/*
* Static Layout
*/


.rd-navbar-static {
  display: block;
  z-index: 1000;
  background-color: $rd-navbar-background;
  overflow: visible;
  &.rd-navbar-mod-1 {
    margin-bottom: -130px;
  }

  // RD Navbar inner
  .rd-navbar-inner {
    position: relative;
    @include display-flex;
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
    max-width: $rd-navbar-width;
    padding: 20px 15px;
    margin-left: auto;
    margin-right: auto;
    transition: height .3s;
  }

  // RD Navbar Top Panel
  .rd-navbar-top-panel {
    padding: 10px 0;
    width: 100%;
    background: $rd-navbar-top-panel-bg;

    &-content {
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(center);
      @include justify-content(space-between);
      max-width: $rd-navbar-width;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: auto;
      margin-right: auto;
    }

    &-content-inner {
      > * {
        display: inline-block;
        vertical-align: middle;
      }
      > * + * {
        position: relative;
        padding-left: 45px;
        &:before{
          position: absolute;
          left: 20px;
          top: 0;
          bottom: 0;
          border-left: 1px solid;
          content: "";
          display: inline-block;
        }
      }
    }
    
    .contact-info {
      font-size: 15px;
      color: $gray;  
    }

    .object-inline {
      position: relative;
      top: 2px;
    }
  }

  .rd-navbar-panel {
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    font-size: 0;
    line-height: 0;
  }

  .rd-navbar-brand {
    max-width: 153px;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  // RD Navbar nav wrap
  .rd-navbar-nav-wrap {
    width: 100%;
    padding-left: 40px;
    text-align: right;
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    font-size: 0;
    line-height: 0;

    > li {
      > .rd-navbar-dropdown {
        margin-top: 25px;
      }
    }

    > li + li {
      margin-left: 27px;
    }

    @media (max-width: $screen-md-max) {
      > li > .rd-navbar-submenu-toggle {
        position: relative;
        top: 1px;
        font-size: 12px;
      }
    }

    @media (min-width: $screen-lg-min) {
      > li + li {
        margin-left: $navbar-static-nav-indent - 10px;
      }
    }
  }

  // States
  &.rd-navbar--is-clone {
    display: block;
    transform: translateY(-106%);
    z-index: 100;

    &.rd-navbar--is-stuck {
      transform: translateY(0);
    }
  }

  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include box-shadow(0 2px 10px 1px rgba($gray-base, .1));

    .rd-navbar-top-panel {
      display: none;
    }

    .rd-navbar-brand img {
      width: auto;
    }
  }

  @media (max-width: 1199px) {
    .btn-lg {
      font-size: 12px;
      padding: 15px 20px;
      min-width: 110px;
    }
  }
}

// Default layout
//
.rd-navbar-static.rd-navbar-default {
  .rd-navbar-inner {
    height: 104px;
  }

  @media (min-width: $screen-lg-min) {
    .rd-navbar-inner {
      height: 126px;
    }

    .rd-navbar-nav > li {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        margin-top: 55px;
      }
    }
  }

  .rd-navbar-nav > li {
    > .rd-navbar-dropdown,
    > .rd-navbar-megamenu {
      margin-top: 40px;
    }
  }

  &.rd-navbar--is-stuck {
    .rd-navbar-inner {
      height: 74px;
    }

    .rd-navbar-nav > li .rd-navbar-dropdown,
    .rd-navbar-nav > li .rd-navbar-megamenu {
      margin-top: 29px;
    }
  }
}

// Transparent layout
//
.rd-navbar-static.rd-navbar-transparent {
  $color: #384353;
  $navbar-static-transparent-panel-width: 215px; // Nav wrap is relative, megamenu doesn't align properly
  background-color: transparent;
  border: 0;
  transition: none;

  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $white;
      background: transparent;
      border-color: $white;
    }

    &:hover {
      color: $white;
      background: $secondary-4;
      border-color: $secondary-4;
    }
  }

  .rd-navbar-inner {
    margin-top: 29px;
    @include align-items(stretch);
    padding: 0 15px;
  }

  .rd-navbar-megamenu {
    left: calc(50% - #{$navbar-static-transparent-panel-width}/2);
    right: 0;
    width: calc(100% + #{$navbar-static-transparent-panel-width});
  }

  .rd-navbar-nav > li > a {
    position: relative;
    padding: 6px 0;

    &,
    &:active,
    &:focus {
      color: $white;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      max-width: 100%;
      border-top: 3px solid;
      transition: width .33s ease;
    }

    &:hover {
      color: $white;
    }
  }

  .rd-navbar-nav > li {
    &.active > a,
    &.focus > a,
    > a:hover {
      &:after {
        width: 100%;
      }
    }
  }

  .rd-navbar-nav > li.rd-navbar-submenu {
    > a {
      &:after {
        max-width: 100%;
      }
    }

    &.active > a,
    &:focus > a,
    > a:hover {
      &:after {
        width: 100%;
      }
    }
  }

  .rd-navbar-nav > li {
    > .rd-navbar-dropdown,
    > .rd-navbar-megamenu {
      margin-top: 40px;
    }

    > .rd-navbar-submenu-toggle:after {
      color: $white;
    }
  }

  .rd-navbar-panel,
  .rd-navbar-nav-wrap {
    @include display-flex;
    @include flex-wrap(nowrap);
    @include align-items(center);
    position: relative;
    height: 95px;
    @extend %rd-navbar-transition;
  }

  .rd-navbar-nav-wrap {
    @include flex-direction(row-reverse);
    justify-content: flex-start;
  }

  .rd-navbar-panel {
    @include flex-direction(row);
  }

  .rd-navbar-panel,
  .rd-navbar-nav-wrap-bg {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      z-index: -1;
      transform-origin: bottom left;
      transform: skew(-30deg, 0deg);
    }
  }

  .rd-navbar-panel {
    min-width: 205px;
    max-width: $navbar-static-transparent-panel-width;
    background: $white;

    @include justify-content(center);
    &:after {
      right: 0;
      background: $white;
    }
  }

  .rd-navbar-nav-wrap-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;

    &:after {
      left: 0;
    }
  }

  .rd-navbar-nav-wrap {
    padding: 20px 15px;
    @include flex-grow(1);

    &-bg:after {
      background: rgba($color, .2);
    }
  }

  * + .rd-navbar-nav-wrap {
    margin-left: 10px;
  }

  @media (max-width: $screen-md-max) {
    .rd-navbar-nav-wrap {
      padding: 20px 8px;
    }
    .rd-navbar-nav {
      > li > a {
        font-size: 14px;
      }
      > li + li {
        margin-left: 8px;
      }
    }
    .rd-navbar-items-list > li {
      padding: 0 7px;
    }
    .rd-navbar-nav-wrap__shop .icon:before {
      font-size: 20px;
    }
    .rd-navbar .rd-navbar-search__toggle::before {
      font-size: 20px;
    }
  }

  // States
  &.rd-navbar--is-clone {
    display: none;
  }

  &.rd-navbar--is-stuck {
    background: $white;

    .rd-navbar-panel,
    .rd-navbar-nav-wrap {
      height: 74px;
    }

    .rd-navbar-inner {
      margin-top: 0;
    }

    .rd-navbar-nav-wrap-bg {
      overflow: visible;

      &:after {
        width: 100vw;
        background: rgba($color, .88);
      }
    }

    .rd-navbar-nav > li .rd-navbar-dropdown,
    .rd-navbar-nav > li .rd-navbar-megamenu {
      margin-top: 22px;
    }
  }
}

// absolute layout
.rd-navbar-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  
  background-color: transparent;
  border-bottom: 0;

  .rd-navbar-inner {
    max-width: 1600px;
  }

  .rd-navbar-nav > li {
    > a {
      position: relative;
      padding: 6px 0;
      @include link($white, $white);

      &:after { 
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        max-width: 100%;
        border-top: 3px solid;
        transition: width .33s ease;
      }
    }
    .rd-navbar-submenu-toggle::after {
      color: $white;  
    }
    
    &.active > a,
    &.focus > a,
    &.opened > a,
    > a:hover {
      color: $white;  
    } 
  }
  .rd-navbar-nav > li {
    
    &.focus,
    &.opened {
      > a,
      > a:hover {
        &:after {
          width: 100%;
        }
        + .rd-navbar-submenu-toggle::after {
          color: $white;
        }
      }
      .rd-navbar-submenu-toggle::after {
        color: $white;  
      }
    }
    > a:hover {
      &:after {
        width: 100%;
      }
    }
    
  }

  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $white;
      background: transparent;
      border-color: $white;
    }

    &:hover {
      color: $white;
      background: $secondary-4;
      border-color: $secondary-4;
    }
  }
  
  &.rd-navbar--is-stuck {
    background: $brand-info;
  }
}