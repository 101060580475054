//
// Box custom
// --------------------------------------------------

// Text block
//
.text-block {
  * {
    display: inline;
  }

  *:not(:last-child) {
    margin-right: .25em;
  }
}

* + .text-block {
  margin-top: 18px;
}

@media (min-width: $screen-sm-min) {
  * + .text-block {
    margin-top: 35px;
  }
}

// Box minimal
//
.box-minimal {
  text-align: center;

  &[class*='unit-'] {
    @media (min-width: $screen-xs-min) {
      text-align: left;
    }
  }

  .unit-left {
    .box-minimal-icon {
      padding-top: 4px;
    }
  }
}

.box-minimal-icon {
  font-size: 40px;
  line-height: 50px;
  color: $mine-shaft;
}

.box-minimal-title {
  @include header-base-styles;
  @include header-h4;
  font-weight: 700;
}

.box-minimal-title.big {
  font-size: 18px;
}

.box-minimal-divider {
  width: 36px;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  background: $brand-primary;
}

.box-minimal-text {
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.box-minimal-modern {
  padding-top: 20px;
  padding-bottom: 20px;

  @media (min-width: $screen-sm-min) {
    padding-right: 15px;
    min-height: 183px;
  }

  @media (min-width: $screen-lg-min) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .box-minimal-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    will-change: transform;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      height: 0;
      width: 3px;
      background-color: $brand-success;
      transition: 350ms ease;
    }
  }

  .box-minimal-title {
    a {
      color: $gray-darker;
      &:hover {
        color: $brand-success;
      }
    }
  }

  &:hover {
    .box-minimal-icon {
      &:before,
      &:after {
        height: 15px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}

h5.box-minimal-title {
  font-size: 18px;
  font-weight: 900;

  & + .box-minimal-text {
    margin-top: 9px;
  }
}

* + .box-minimal-title {
  margin-top: 10px;
}

* + .box-minimal-divider {
  margin-top: 10px;
}

* + .box-minimal-text {
  margin-top: 15px;
}

// Counter box
//
.counter-wrap {
  * + & {
    margin-top: 30px;
  }
}

.counter-box {
  padding: 15px;
  color: $gray-base;
  text-align: center;

  .counter {
    font: 900 45px $font-family-base;
    line-height: 1;
    text-transform: uppercase;
  }

  .counter-box-header {
    font: 400 12px/24px $font-family-base;
    text-transform: uppercase;
  }
  @media (min-width: $screen-sm-min) {
    padding: 0 15px;
  }
}

.counter-k {
  &:after {
    content: 'k';
  }
}

.counter-plus {
  &:after {
    content: '+';
  }
}

.counter-percent {
  &:after {
    content: '%';
    padding-left: 5px;
    font-family: inherit;
    font-weight: inherit;
    font-size: 24.25px;
    vertical-align: top;
  }
}

* + .counter-box-header {
  margin-top: 5px;
}

@media (min-width: $screen-lg-min) {
  .counter-box {
    .counter {
      font-size: 80px;
    }

    .counter-box-header {
      font-size: 14px;
    }
  }
}

// Counter variant 2
//
.counter-box-bold {
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(center);
  @include justify-content(center);
  padding: 40px 10px;
  text-align: center;
  .counter {
    @include header-base-styles;
    font-size: 78px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0;

    @media (min-width: $screen-lg-min) {
      font-size: 80px;

    }
  }

  .counter-k:after {
    font-size: .5em;
  }

  .counter-box-header {
    font: 400 14px $font-family-sec;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}

@media (min-width: $screen-lg-min) {
  .counter-box-bold {
    padding: 35px 5px 35px;
  }

  .counter-box-bold-2 {
    @media (max-width: $screen-lg-max) {
      .counter {
        font-size: 64px;
      }
    }

    .counter-box-header {
      font-size: 14px;
    }
  }
}

@media (min-width: $screen-xl-min) {
  .counter-box-bold {
    padding: 60px 15px 60px;

    &.counter-box-bold-2 {
      padding: 30px 10px 30px;
    }
  }
}

// Counter box minimal
//
.counter-box-minimal {
  text-align: center;
  .counter {
    @include header-base-styles;
    font-size: 34px;
    line-height: 1;
    letter-spacing: 0;
    vertical-align: middle;
    white-space: nowrap;

    @media (min-width: $screen-sm-min) {
      font-size: 44px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 50px;
    }

    @media (min-width: $screen-lg-min) {
      font-size: $font-size-h1;
    }

    &:after {
      display: inline-block;
      padding: 0 .1em;
      font: inherit;
      font-weight: 400;
      font-size: .45em;
      vertical-align: middle;
    }
  }

  &-title {
    font-family: $font-family-sec;
  }
}

* + .counter-box-minimal-title {
  margin-top: 2px;
}

@media (min-width: $screen-md-min) {
  .counter-box-minimal-title {
    font-size: 18px;
  }
}

//counter box with border 
.counter-box-border {
  border: 1px solid $gray-1;
  padding: 38px 35px 33px;
  transition: .3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  .counter-icon {
    font-size: 66px;
    font-weight: 900;
    line-height: .5;
    color: $gray-4;

    &:before {
      padding-right: 7px;
      padding-top: 5px;
      font-size: 30px;
      color: $brand-success;
      vertical-align: top;
    }
  }

  .counter-box-header {
    margin-top: 20px;
    color: $gray;
  }

  &:hover {
    background: $brand-primary;
    border-color: $brand-primary;

    .counter-icon,
    .counter-icon:before,
    .counter-box-header {
      color: $white;
    }
  }
}

// counter boxed layout
.counter-boxed-layout {
  background-color: $gray-light;
  padding: 25px 15px;
  box-shadow: 3px 0 8px rgba($gray-base, .15);

  .counter,
  &.counter-box p.counter-box-header {
    color: $gray-darker;
  }

}

.counter-boxed-layout-mod-1 {
  background-color: $gray-light;
  padding: 25px 0 0;
  box-shadow: 3px 0 8px rgba($gray-base, .15);

  .counter {
    color: $gray-darker;
  }

  .counter-box-header {
    padding: 10px;
    background-color: $gray-darker;
    color: $white;
  }
}

// Box profile
//
.box-profile {
  text-align: left;
}

.box-profile-image {
  width: 100%;
  height: auto;
}

.box-profile-name {
  @include header-base-styles;
  @include header-h4;
}

.box-profile-description {
  font: 500 16px $font-family-sec;
  line-height: 1.2;
  letter-spacing: 0;
  color: $blue-marguerite;
}

.box-profile-text {
  * + p {
    margin-top: 14px;
  }

  * + .box-profile-title {
    margin-top: 20px;
  }
}

.box-profile-title {
  @include header-base-styles;
  @include header-h5;
}

* + .box-profile-body {
  margin-top: 30px;
}

* + .box-profile-description {
  margin-top: 0;
}

* + .box-profile-text {
  margin-top: 14px;
}

@media (min-width: $screen-md-min) {
  .box-profile {
    @include display-flex;
    @include flex-direction(row-reverse);
    @include flex-wrap(nowrap);
    @include align-items(stretch);
    @include justify-content(space-between);
  }

  .box-profile-left,
  .box-profile-body {
    width: calc(50% - 15px);
  }

  .box-profile-description {
    font-size: 18px;
  }

  * + .box-profile-text {
    margin-top: 19px;
  }

  .box-profile-text {
    * + p {
      margin-top: 19px;
    }

    * + .box-profile-title {
      margin-top: 30px;
    }
  }

  * + .box-profile-body {
    margin-top: 0;
  }
}

// Box inset
//
.box-inset {
  padding: 30px;
  background: $gray-light;
  text-align: left;
}

@media (min-width: $screen-sm-min) {
  .box-inset {
    padding: 60px 40px;
  }
}

// Box triangle
//
.box-triangle {
  background: $gray-light;
  text-align: left;
}

.box-triangle-image {
  width: 100%;
  height: auto;
}

.box-triangle-body {
  position: relative;
  padding: 25px 15px 30px 15px;
  background: $gray-light;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: -10px;
    @include transform(translateX(-50%) rotate(-45deg));
    width: 20px;
    height: 20px;
    background: $gray-light;
    z-index: 2;
    pointer-events: none;
  }

  > * {
    position: relative;
    z-index: 2;
  }
}

.box-triangle-body {
  * + .range {
    margin-top: 15px;
  }
}

.box-triangle + .box-triangle {
  margin-top: 30px;
}

@media (min-width: $screen-xs-min) {
  .box-triangle-body {
    padding: 30px 40px 40px 40px;
  }
}

@media (min-width: $screen-sm-min) {
  .box-triangle-sm-horizontal {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(stretch);
    @include justify-content(space-between);
  }

  .box-triangle-sm-horizontal.box-triangle-reverse {
    @include flex-direction(row-reverse);
  }

  .box-triangle-sm-horizontal {
    .box-triangle-left {
      position: relative;
      overflow: hidden;
      @include flex-shrink(0);
      width: calc(50% - 15px);
    }

    .box-triangle-image {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      height: auto;
      width: auto;
      min-width: 101%;
      max-width: none;
      min-height: 101%;
    }

    .box-triangle-body {
      @include flex-grow(1);
      &:before {
        top: 50%;
        @include transform(translateY(-50%) rotate(-45deg));
        left: -10px;
      }
    }

    &.box-triangle-reverse {
      .box-triangle-body:before {
        left: auto;
        right: -10px;
      }
    }
  }

  .box-triangle-body {
    padding: 40px 40px 50px 30px;
  }
}

@media (min-width: $screen-lg-min) {
  .box-triangle {
    min-height: 389px;
  }

  .ie-10,
  .ie-11 {
    .box-triangle {
      &:after {
        content: '';
        display: inline-block;
        min-height: inherit;
      }
    }
  }
}

// Box cta
//
.box-cta {
  font-family: $font-family-sec;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  text-transform: uppercase;
  color: $mischka;
  background: $gray-light;
  text-align: center;
}

.box-cta-body {
  padding: 30px 20px 20px;
  overflow: hidden;
  white-space: nowrap;
}

.box-cta-subtitle {

  > * + * {
    margin-top: 7px;
  }
}

.box-cta-title {
  font-size: 42px;
}

* + .box-cta-subtitle {
  margin-top: 10px;
}

.box-cta-subtitle-top {
  display: inline-block;
  font-size: 18px;
  padding: .4em .4em .2em;
  color: $white;
  background: $tan-hide;
}

.box-cta-subtitle-bottom {
  font-size: 36px;
}

.box-cta-control {
  min-width: 200px;

  .btn {
    padding: 18px;
  }
}

@media (min-width: $screen-xs-min) {
  .box-cta-title {
    font-size: 60px;
    padding-right: 10px;
  }

  .box-cta-subtitle-bottom {
    font-size: 44px;
  }

  * + .box-cta-subtitle {
    margin-top: 0;
  }
}

@media (min-width: $screen-sm-min) {
  .box-cta {
    @include display-flex;
    @include flex-direction(row);
    @include justify-content(center);
    text-align: left;
  }

  .box-cta-body {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(stretch);
    @include justify-content(flex-start);
    padding: 17px 36px 10px;
    min-width: (100% - 28.2%);
  }

  .box-cta-control {
    @include justify-content(flex-start);

    &, > * {
      @include display-flex;
      @include flex-direction(column);
      @include flex-wrap(nowrap);
      @include align-items(center);
    }

    > * {
      @include justify-content(center);
      @include flex-grow(1);
      width: 100%;
      max-height: 130px;
    }
  }

  .box-cta-title {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(flex-start);
    @include justify-content(center);
    font-size: 52px;
    padding-right: 25px;
  }

  .box-cta-subtitle-top {
    font-size: 12px;
  }

  .box-cta-subtitle-bottom {
    font-size: 18px;
  }
}

@media (min-width: $screen-md-min) {
  .box-cta-title {
    font-size: 66px;
  }

  .box-cta-control {
    width: 100%;
    min-width: 240px;
    max-width: 28.2%;
  }

  .box-cta-subtitle-top {
    font-size: 14px;
  }

  .box-cta-subtitle-bottom {
    font-size: 34px;
  }
}

@media (min-width: $screen-lg-min) {
  .box-cta-body {
    padding: 17px 36px 0;
  }

  .box-cta-title {
    font-size: 97px;
  }

  .box-cta-subtitle-top {
    font-size: 18px;
  }

  .box-cta-subtitle-bottom {
    font-size: 48px;
  }

  .box-cta-control {
    width: 100%;
    max-width: 28.2%;
  }
}

// Box icon
//
.box-icon {
  text-align: center;
}

.box-icon-image {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
    height: auto;
    transition: $transition-md;
    opacity: 1;
    transform: scale(1);

    &:hover {
      opacity: .9;
      transform: scale(1.05);
    }
  }
}

.box-icon-body {
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

* + .box-icon-body {
  margin-top: 10px;
}

@media (min-width: $screen-xs-min) {
  .box-icon-body {
    padding: 0 5px;
  }
}

// Box line
//
.box-line {
  position: relative;
  background: $gray-light;
  padding: 30px 20px 40px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: $brand-primary;
  }

  &__mod-1 {
    overflow: hidden;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    &:after {
      content: '';
      position: absolute;
      top: -20px;
      right: -30px;
      width: 90px;
      height: 50px;
      background-color: $white;
      transform: rotate(40deg);
    }
  }
}

.box-line-success {
  &:before {
    background: $brand-success;
  }
}

.box-line + * {
  margin-top: 35px;
}

.box-line + .box-line {
  margin-top: 30px;
}

@media (min-width: $screen-sm-min) {
  .box-line {
    padding: 40px;
  }

  .box-line + * {
    margin-top: 45px;
  }
}

// Box form
//
.box-form {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 16px 0 rgba($gray-base, .11);
  background: $white;
  padding: 30px 20px 45px;

  .rd-mailform .select2-container--bootstrap .select2-selection {
    min-width: 100px;
  }

  .select2-container--bootstrap .select2-selection {
    background: $gray-light;
  }

  .btn {
    min-height: $input-height-base;
  }
}

.box-form-1 {
  margin-top: -30px;
}

@media (min-width: $screen-sm-min) {
  .box-form-2 {
    padding: 50px;
  }
}

@media (min-width: $screen-lg-min) {
  .box-form-1 {
    padding: 44px 100px 50px 74px;
  }
}

// Box text
.page .box-text {
  #{headings()} {
    color: $mischka;
  }

  > * + * {
    margin-top: 0;
  }

  .btn {
    min-width: 220px;
  }

  @media (min-width: $screen-lg-min) {
    h1,
    .h1 {
      font-size: 97px;
    }

    .h3,
    h3 {
      font-size: 36px;
    }
  }
}

// Box wrap
//
.box-wrap {
  position: relative;
}

.box-wrap-content {
  padding: 30px 0;
  pointer-events: none;
  border-bottom: 1px solid $hr-border;
  text-align: center;
}

.box-wrap-content-interactive {
  pointer-events: auto;
}

@mixin box-wrap-overlap {
  border-bottom: 0;

  .box-wrap-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include display-flex;
    background: $gray-light;

    .rd-google-map {
      @include display-flex;
      width: 100%;
      height: 100%;
    }
  }

  .box-wrap-content {
    position: relative;
    z-index: 2;
    padding: 72px 0;
    border: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .box-wrap {
    .shell {
      max-width: 100%;
    }
  }
}

@media (min-width: $screen-xs-min) {
  .box-wrap {
    @include box-wrap-overlap;
  }

  .box-wrap-content {
    text-align: right;
  }

  .box-wrap-content-interactive {
    display: inline-block;
  }
}

// Box contacts
//
.box-contacts {
  display: inline-block;
  padding: 30px 40px;
  background: $white;
  text-align: left;

  address {
    max-width: 145px;
  }

  h4 {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  * + h4,
  * + .h4 {
    margin-top: 30px;
  }
  h4 + *,
  .h4 + * {
    margin-top: 7px;
  }
}

@media (min-width: $screen-sm-min) {
  .box-contacts {
    padding: 50px 60px;
  }
}

@media (min-width: $screen-md-min) {
  .box-contacts {
    padding: 50px 100px;
  }
}

// Box tabs
//
.box-tabs {
  padding: 50px 20px;
  text-align: center;
  background: $white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.11);
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(center);
  @include justify-content(center);

  .tabs-custom {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }

  .tabs-custom .nav-tabs,
  .tabs-custom .tab-content {
    text-align: center;
  }
}

@media (min-width: $screen-sm-min) {
  .box-tabs {
    padding: 70px 50px;
  }
}

@media (min-width: $screen-xl-min) {
  .box-tabs {
    margin: -40px -30px;
  }
}

// Box spacer
//
.box-spacer {
  padding: 40px 20px;
  color: $gray-darker;

  .tabs-custom {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-lg-min) {
  .box-spacer {
    padding: 100px 50px 50px;
  }
}

// Box spacer md
//
.box-spacer-md {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  padding: 20px;
  background: $white;

  .box-spacer-title {
    @include header-base-styles;
    font-size: 56px;
    line-height: 1;
    color: $brand-success;
  }
}

@media (min-width: $screen-sm-min) {
  .box-spacer-md {
    padding: 50px 65px;

    .box-spacer-title {
      font-size: 56px;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .box-spacer-md {

    .box-spacer-title {
      font-size: 95px;
    }
  }
}

// Box item
//
.box-item {
  max-width: 290px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.box-item-icon {
  font-size: 40px;
  color: $brand-primary;
}

* + .box-item-title {
  margin-top: 14px;
}

* + .box-item-text {
  margin-top: 11px;
}

@media (min-width: $screen-sm-min) {
  .box-item {
    padding: 0 0 10px;
  }
}

// Box promo
//
@media (max-width: $screen-xs-max) {
  .box-promo {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .box-promo-1 {
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}

.box-promo {
  @media (min-width: $screen-sm-min) {
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}

.box-promo-1 {
  @media (min-width: $screen-sm-min) {
    max-width: 240px;
    .box-promo-header {
      text-align: right;
    }
  }

  @media (min-width: $screen-md-min) {
    max-width: 200px;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 290px;
  }
}

// Box bordered
//
.box-bordered {
  position: relative;
  margin-top: 38px;
  text-align: center;
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);

  &,
  &-icon {
    background: $white;
  }

  border: 5px solid $gray-light;
  border-top: 0;

  h3 {
    font-size: 30px;
    span + span {
      margin-left: .25em;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    border-top: 5px solid $gray-light;
  }

  &:before {
    left: 0;
    right: calc(50% + 40px);
  }

  &:after {
    left: calc(50% + 40px);
    right: 0;
  }

  * + p {
    margin-top: 10px;
  }
}

.box-bordered-icon {
  position: absolute;
  top: -38px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  width: 87px;
  height: 87px;
  border-radius: 100%;
  line-height: 80px;
  color: $brand-primary;
  font-size: 40px;
  border: 5px solid $gray-light;

  &:before {
    position: relative;
    display: block;
    transition: .6s all ease;
  }
}

.box-bordered-body {
  padding: 75px 20px 40px;
  max-width: 100%;
}

@media (min-width: $screen-sm-min) {
  .box-bordered-body {
    padding: 60px 35px 40px;
  }
}

.desktop {
  @media (min-width: $screen-md-min) {
    .box-bordered {
      position: relative;
      transform: scale(1);
      -webkit-filter: blur(0);
      transition: .44s all ease;
      cursor: default;

      &:before,
      &:after {
        transition: .3s all ease;
      }

      &:hover {
        transform: scale(1.03);

        //&:before {
        //  left: 0;
        //  right: calc(50% + 90px);
        //}
        //
        //&:after {
        //  left: calc(50% + 90px);
        //  right: 0;
        //}
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  .box-bordered {
    margin: 0 10px;
  }

  .box-bordered-body {
    padding: 80px 65px 50px;
  }
}

//box contact info
.box-contact-info {
  background-color: $wild-sand;
  width: 100%;
}

.box-contact-item {
  padding: 40px 0;
  .unit-body {
    padding-top: 7px;
  }

  > *:first-of-type {
    padding-left: 30px;
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
    color: $gray-darker;
  }

  & + .box-contact-item {
    border-top: 1px solid $gray-2;
  }
}

.box-bordered-variant-2 {
  position: relative;
  padding: 25px 15px;
  background-color: $white;
  perspective: 1000px;
  overflow: hidden;

  .box-minimal-divider {
    margin-top: 17px;
    margin-bottom: 20px;
    transition: $transition-md;
  }

  .box-minimal-icon {
    transition: $transition-md;
  }

  &:before {
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    content: "";
    display: inline-block;
    border: 1px solid $brand-success;
    transition: $transition-md;
    transform: translate3d(0, 0, 150px);
    pointer-events: none;
  }

  @media (min-width: $screen-sm-min) {
    padding: 45px 50px;

    &:hover {
      &:before {
        transform: translate3d(0, 0, 0);
      }
      .icon-primary {
        color: $brand-success;
      }
      .box-minimal-divider {
        width: 106px;
        background-color: $brand-success;
      }
    }
  }
}

//---------------------------------------------------
// Pricing box
//---------------------------------------------------

.pricing-carousel {
  .owl-stage {
    padding: 20px 0;
  }

  &-2,
  &-3 {
    .owl-stage {
      padding: 20px 0;
    }
  }

  @media (min-width: $screen-md-min) {
    .owl-stage {
      padding: 20px 10px;

      > .owl-item {
        &:first-of-type {
          > * {
            margin-right: -10px;
          }
        }
        &:last-of-type {
          > * {
            margin-left: -10px;
          }
        }
      }
    }

    &,
    &-3 {
      .owl-stage {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-2 {
      .owl-item + .owl-item {
        border-left: 1px solid $gray-1;
      }
    }

    &-3 {
      .owl-item {
        margin-left: 25px;
      }
    }
  }
}

.owl-carousel {
  &[class*='pricing-'] {
    @media (min-width: $screen-md-min) {
      .owl-controls {
        display: none;
      }
    }
  }
}

// pricing box 1
.pricing-box-1 {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 15px;
  box-shadow: $shadow-area-md;
  background-color: $white;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    padding: 45px 25px 60px;
  }

  @media (min-width: $screen-md-min) {
    &.active {
      position: relative;
      z-index: 2;
      padding: 75px 25px 85px;
    }
  }
}

.pricing-title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  color: $brand-primary;
}

.pricing-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 133px;
  height: 133px;
  border: 6px solid #d1e1f7;
  background-color: #f8f9fb;
  border-radius: 50%;

  * + & {
    margin-top: 20px;
  }
}

.pricing-list {
  li {
    padding: 2px;

    > *:first-of-type {
      color: $gray-darker;
      font-weight: 700;
    }
  }
  * + & {
    margin-top: 25px;
  }

  & + .btn {
    margin-top: 30px;
  }
}

.pricing-list-marked {
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  li {
    display: flex;
    padding: 5px 2px;
    color: $gray-darker;

    &:before {
      display: inline-block;
      min-width: 29px;
      padding-right: 10px;
      content: '\e005';
      font: 400 13px/24px "fl-flat-icons-set-2";
      color: $brand-success;
    }
  }

  &__mod-1 {
    li {
      &:nth-child(n + 2) {
        opacity: .6;
        &:before {
          content: "\e007";
          color: $gray; 
        }
      }
    }
  }
  &__mod-2 {
    li {
      &:nth-child(n + 4) {
        opacity: .6;
        &:before {
          content: "\e007";
          color: $gray;
        }
      }
    }
  }

  * + & {
    margin-top: 40px;
  }
}

.pricing-divider {
  max-width: 155px;
  margin: 30px auto 20px;
}

.price-wrap {
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  text-transform: uppercase;

  .price {
    display: block;
    font-size: 36px;
    line-height: 1.1;
    font-weight: 300;
    color: $gray-darker;

    &:before {
      content: "$";
    }
  }

  & + .btn {
    margin-top: 30px;
  }

  @media (min-width: $screen-sm-min) {
    .price {
      font-size: 50px;
    }
  }
  @media (min-width: $screen-lg-min) {
    .price {
      font-size: 86px;
    }
  }
}

.pricing-label {
  position: relative;
  display: inline-block;
  padding: 2px 12px;
  background-color: $brand-success;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.6; 

  @media (min-width: $screen-xs-min) {
    &:before {
      position: absolute;
      top: 0;
      right: -10px;
      content: "";
      display: inline-block;
      @include triangle(right, 10px, 26px, $brand-success);
      vertical-align: middle;
    }
  }
}

.pricing-title + .price-wrap {
  margin-top: 10px;
}

//pricing box 2
.pricing-box-2 {
  padding: 25px 15px;
  background-color: $gray-light;
  max-width: 370px;
  margin-left:auto;
  margin-right: auto;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  
  &_mod-1 {
    padding: 0; 
    background-color:transparent;
    
    .pricing-list-marked + .btn { 
      margin-top: 30px;
    }
  }   
  
  @media (min-width: $screen-sm-min) {
    padding: 65px 25px 90px;
  }

  .pricing-title {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  * + .pricing-list-marked {
    margin-top: 30px;
  }

  .price-wrap {
    font-weight: 400;
    .price {
      display: inline;
      font-size: 86px;
      line-height: 1;

      &:before {
        position: relative; 
        top: 17px;
      }
    }

    & > *,
    .price:before {
      font-size: 46px;
      line-height: 1;
      color: $gray-darker;
      vertical-align: text-top;
    }
  }

  .btn-silver-outline {
    &,
    &:active,
    &:focus {
      color: $gray;
      background: transparent;
      border-color: $gray-1;
    }

    &:hover {
      color: $gray-darker;
      background: $gray-1;
      border-color: $gray-1;
    }
  }

  * + .btn {
    margin-top: 25px;
  }

  @media (min-width: $screen-md-min) {
    & {
      min-height: 605px;
    }
    &.active {
      position: relative;
      min-height: 645px;
      z-index: 2;
      padding: 90px 25px 110px;
      background-color: $white;
      box-shadow: 0 0 59px rgba($gray-darken, .11);
    }
    * + .btn {
      margin-top: auto;
    }
  }
}

//pricing box 3
.pricing-box-3 {
  padding: 25px 15px;
  background-color: $gray-light;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .price-wrap {
    font-weight: 400;
    .price {
      display: inline;
      font-size: 60px;
      line-height: 0.7;
      &:after {
        content: none;
      }
      &:before {
        position: relative;
        top: 15px;
      }
    }

    span {
      display: inline-block;
      padding-left: 5px;
      &:after {
        content: 'mon';
        font-size: 16px;
        display: block;
      }
    }
    span,
    .price:before {
      font-size: 24px;
      line-height: 1;
      color: $gray-darker;
      vertical-align: text-top;
    }
  }

  .pricing-title {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .075em;
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-1;

    &:before,
    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &:before {
      content: "";
      @include triangle(bottom, 28px, 15px, $gray-1);
    }
    &:after {
      content: '';
      margin-top: -1px;
      @include triangle(bottom, 28px, 15px, $gray-light);
    }

    & + * {
      margin-top: 30px;
    }
  }

  &.active {
    border-top: 2px solid $brand-success;
  }
  @media (min-width: $screen-md-min) {
    padding: 45px 40px 60px;

    &.active {
      padding: 56px 40px 71px;
    }
  }
}

// box 
.box-bordered-variant-3-wrap {
  [class*='cell-'] {
    @media (min-width: $screen-xl-min) {
      & + [class*='cell-'] {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 100%;
          width: 45px;
          height: 1px;
          background-color: $gray-1;
          margin-right: -20px;
        }
      }
    }
  }
}

.box-bordered-variant-3 {
  padding: 25px 15px;
  border: 1px solid $gray-1;

  @media (min-width: $screen-md-min) {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
    padding: 55px 25px 70px;
  }
}

//========================================
// animated box
//========================================

.animated-box-wrap {
  perspective: 1000px;
  position: relative;
  max-width: 700px;

  * + h4 {
    margin-top: 15px;
  }
  html:not([data-x-mode]) & {
    &:hover .animated-box {
      transform: rotateY(180deg);

      .animated-box-back .animated-box-inner {
        transform: translate3d(0, 0, 0);
      }
      .animated-box-front .animated-box-inner {
        transform: translate3d(0, 0, 90px);
      }
    }
    &-vertical {
      &:hover .animated-box {
        transform: rotateX(180deg);
      }
    }
  }
  
  &.active {
    .animated-box {
      transform: rotateY(180deg);

      .animated-box-back .animated-box-inner {
        transform: translate3d(0, 0, 0);
      }
      .animated-box-front .animated-box-inner {
        transform: translate3d(0, 0, 90px);
      }
    }
    
    &.animated-box-wrap-vertical {
      .animated-box {
        transform: rotateX(180deg);
      }
    }
  }
  

  &-vertical {
    .animated-box-back {
      transform: rotateX(-180deg);
    }
  }
}

.animated-box {
  transform-style: preserve-3d;
  border: 1px solid $gray-light;
  box-shadow: $shadow-area-xs;
  text-align: center;
  transition: all 0.7s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.animated-box-inner {
  transition: all 0.5s cubic-bezier(0.5, 0.12, 0.46, 0.88);
}

.animated-box-front,
.animated-box-back {
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  min-height: 250px;
  padding: 25px;
  transition: all 0.1s 0.25s cubic-bezier(0.5, 0.12, 0.46, 0.88);

  &.bg-image { 
    background-size: cover;
    @extend %context-dark;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($gray-base, .3);
    }
  }

  @media (min-width: $screen-sm-min) {
    min-height: 350px;
  }
}

.animated-box-front {
  background-color: $white;
  backface-visibility: hidden;
  z-index: 1;
  transform: translate3d(0, 0, 1px);

  .animated-box-inner {
    transform: translate3d(0, 0, 0);
  }
}

.animated-box-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  transform: rotateY(180deg) translate3d(0, 0, 1px);
  background-color: $brand-primary;

  .animated-box-inner {
    transform: translate3d(0, 0, 110px);
  }

  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $gray-darker;
      background: transparent;
      border-color: $gray-darker;
    }

    &:hover {
      color: $gray-darker;
      background: $secondary-4;
      border-color: $secondary-4;
    }
  }
}

.ie-10,
.ie-11,
.ie-edge {

  .animated-box-wrap {
    .animated-box-back {
      opacity: 0;
    }
    &:hover {
      .animated-box-front {
        opacity: 0;
        pointer-events: none;
      }
      .animated-box-back {
        opacity: 1;
      }
    }
  }

  .animated-box-front,
  .animated-box-back {
    height: 250px;

    @media (min-width: $screen-sm-min) {
      height: 350px;
    }
  }
}

// anim box skyscraper
.animated-box-skyscraper {

  .animated-box-front,
  .animated-box-back {
    min-height: 300px;
    justify-content: space-between;
    flex-flow: row wrap;

    @media (min-width: $screen-sm-min) {
      min-height: 400px;
    }
  }
  .animated-box-inner {
    flex-grow: 1;
    width: 100%;
  }

  &-left {
    .animated-box {
      text-align: left;
    }
    hr {
      margin-left: 0;
    }
    .animated-box-front,
    .animated-box-back {
      align-items: flex-start;
    }
  }
  
  &-right {
    .animated-box {
      text-align: right;
    }
    hr {
      margin-right: 0;
    }
    .animated-box-front,
    .animated-box-back {
      align-items: flex-end;
    }
  }
  
  &-center {
    .animated-box-front,
    .animated-box-back {
      justify-content: center;
    }
    .animated-box-back {
      transform: rotateX(-180deg);
    }
  }
  
  &.active {
    &.animated-box-skyscraper-right {
      .animated-box {
        transform: rotateY(-180deg);
      }
    }
    &.animated-box-skyscraper-center {
      .animated-box {
        transform: rotateX(180deg);
      }
    }
  }
}
html:not([data-x-mode]) {
  .animated-box-skyscraper {
    &-right {
      &:hover .animated-box {
        transform: rotateY(-180deg);
      }
    }

    &-center {
      &:hover .animated-box {
        transform: rotateX(180deg);
      }
    }
  }
}

.ie-10,
.ie-11,
.ie-edge {
  .animated-box-skyscraper {
    .animated-box-front,
    .animated-box-back {
      height: 300px;

      @media (min-width: $screen-sm-min) {
        height: 400px;
      }
    }
  }
}

// anim box transparent
.animated-box-transparent {
  
  .animated-box {
    border-radius: 15px;
    border-color: $brand-success;
  }
  
  .animated-box-back {
    opacity: 0;
  }

  html:not([data-x-mode]) & {
    &:hover {
      .animated-box-front {
        opacity: 0;
        pointer-events: none;
      }
      .animated-box-back {
        opacity: 1;
      }
    }
  }
  
  &.active {
    .animated-box-front {
      opacity: 0;
      pointer-events: none;
    }
    .animated-box-back {
      opacity: 1;
    }
  }
 

  .animated-box-front,
  .animated-box-back {
    background: transparent;
  }
}


//anim box minimal
.animated-box-ordered {
  counter-reset: div;
  
  .animated-box-title {
    &:before{
      display: block;
      content: counter(div, decimal-leading-zero) '';
      counter-increment: div;
      font-size: 36px;
    }
  }

  .animated-box {
    border-radius: 15px;
    border-color: $brand-success;
  }

  .animated-box-ordered-item {
    .animated-box-front,
    .animated-box-back {
      border-radius: 15px;
      min-height: 220px;

      @media (min-width: $screen-sm-min) {
        min-height: 260px;
      }
    }
  }
  
  * + .btn {
    margin-top: 30px;
  }
}

// anim box product

.animated-box-wrap-product {
  .animated-box-back {
    background-color: $white;
    align-items: stretch;
    
    > * {
      flex-grow: 1;
      width: 100%;
    }

    .animated-box-inner {
      > * {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.ie-10,
.ie-11,
.ie-edge {
  .animated-box-wrap-product {
    .animated-box-front,
    .animated-box-back {
      height: auto;
      
    }
  }
}
