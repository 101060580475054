// Shop
//
//-----------------------------

.product-wrap {
  .btn {
    border-width: 1px;
  }
  
  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $white;
      background: transparent;
      border-color: $white;
    }

    &:hover {
      color: $gray-darker;
      background: $white;
      border-color: $white;
    }
  }

  a.icon-white:hover {
    color: $brand-primary;
  }

  .product-caption {
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 30px;
  }
}

.product-image {
  position: relative;
  img {
    width: 100%;
    height: auto; 
  }
}

.product-caption {
  background-color: rgba($black, .39);

  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $gray-darker;
      background: transparent;
      border-color: $gray-darker;
    }

    &:hover {
      color: $gray-darker;
      background: $secondary-4;
      border-color: $secondary-4;
    }
  }
}

.product-price {
  position: absolute;
  left: 25px;
  bottom: 30px;
  background-color: $white;
  padding: 6px 7px 6px 16px;
  color: $gray-darker;

  > * {
    padding-left: 4px;
    padding-right: 4px;
    
    &:before{
      content: "$";
      display: inline-block;
    }
  }
}

.list-product {
  text-align: center;
  > li {
    display: inline-block;
    padding-left: 14px;
    padding-right: 9px;
    line-height: 1;

    & + li {
      border-left: 1px solid $white;
    }
  }
}

.product-caption-centered {
  > * + * {
    margin-top: 10px;
  }
}

.product-caption {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-favorite {
  position: absolute;
  top: 15px;
  right: 15px;
}

.product-title {
  padding: 10px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.075em;
  a{
    display: inline;
  }
}

.product-carousel {
  @media (min-width: $screen-md-min) {
    padding-right: 46px;
  }
}

.rating-list {
  > li {
    display: inline-block;
    letter-spacing: -3px;
    vertical-align: middle;
  }

  .icon {
    font-size: 19px;
  }
}

.desktop {
  @media (min-width: $screen-sm-min) {
    .product-wrap {
      
      overflow: hidden;

      figure {
        transform: translate3d(0,0,0);
        transition: 1000ms ease-in-out;
      }
      .product-caption {
        opacity: 0;
        filter: blur(30px);
        transition: 450ms ease-in;

        .btn {
          opacity: 0;
          filter: blur(30px);
          transition: 450ms 300ms ease-in;
          transform: translate3d(0,40px,0);
        }
      }
      .list-product {
        opacity: 0;
        filter: blur(20px);
        transition: 450ms 300ms ease-in;
        transform: translate3d(0,-40px,0);
      }
    }

    .product-wrap:hover {
      .product-caption {
        opacity: 1;
        filter: blur(0);

        .btn {
          opacity: 1;
          filter: blur(0);
          transform: translate3d(0,0,0);
          &:hover {
            transition: 350ms ease-in;
          }
        }
      }
      figure {
        transform: translate3d(0,0,20px);
        filter: blur(.8px);
      }
      .list-product {
        opacity: 1;
        filter: blur(0);
        transform: translate3d(0,0,0);
      }
    }
  }
}
