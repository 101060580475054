//
// Page title
// --------------------------------------------------
.page-title {
  position: relative;
  z-index: 1;
  padding: 40px 15px;
  text-align: center;

  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-color: #1b181d;
  background-image: url(../images/bg-image-1.jpg); 
}

.page-title-text {
  @include header-base-styles;
  font-size: 28px;
  line-height: 1.2;
  color: $white;
}

@media (min-width: $screen-sm-min) {
  .page-title {
    padding: 60px 15px;
  }

  .page-title-text {
    font-size: 42px;
  }
}

.desktop{
  @media (min-width: $screen-md-min) {
    .page-title {
      padding: 220px 15px 60px; 
      
      &-custom {
        padding-top: 130px;
        padding-bottom: 130px;
      }
    }
    .page-title-text {
      font-size: 48px;
    }
  }
}


@media (min-width: $screen-lg-min) {
  .page-title {
    padding: 250px 15px 85px;
  }
}
