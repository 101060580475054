// Element groups
// --------------------------------------------------

html .page {
  [class*='group-'] {
    &:before,
    &:after {
      content: '';
      display: table;
    }
  }

  .group {
    @include element-groups-custom(15px, 15px);
  }

  .group-xs {
    @include element-groups-custom(5px, 5px);
  }

  .group-sm {
    @include element-groups-custom(16px, 10px);
  }

  .group-md {
    @include element-groups-custom(25px, 20px);
  }

  .group-lg {
    @include element-groups-custom(35px, 15px);
  }

  .group-xl {
    @include element-groups-custom(15px, 15px);
    @media (min-width: $screen-md-min) {
      @include element-groups-custom(40px, 25px);
    }
    @media (min-width: $screen-lg-min) {
      @include element-groups-custom(90px, 30px);
    }
  }

  .group-top {
    > *, > *:first-child {
      vertical-align: top;
    }
  }

  .group-middle {
    > *, > *:first-child {
      vertical-align: middle;
    }
  }

  .group-bottom {
    > *, > *:first-child {
      vertical-align: bottom;
    }
  }

  .group-sm-justify {
    .btn {
      min-width: 200px;
    }
    @media (min-width: $screen-sm-min) {
      @include display-flex;
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(center);

      > * {
        @include flex-grow(1);
        @include flex-shrink(0);
      }

      .btn {
        min-width: 130px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .group-md-1 {
    @include element-groups-custom(20px, 10px);
  }
}