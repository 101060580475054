//
// Backgrounds
// --------------------------------------------------
@mixin bg-behaviour($bg-color) {
  background-color: $bg-color;

  & + & {
    padding-top: 0;
  }

  .bg-decoration > svg {
    fill: $bg-color;
  }
}

// Bg colors
//
%context-dark {
  color: $white;

  #{headings()},
  .h3-alternate,
  .h4-alternate {
    color: $headings-color-inverse;
  }
  
  a.thumbnail-modern-title {

      &:hover{
        color: $brand-primary;   
      }

  }

  .countdown {
    color: $white;
  }

  .rd-mailform {
    .form-control {
      background: rgba($white, .47);
    }

    .form-control,
    .form-label,
    .form-label-outside,
    .form-validation {
      color: $white;
    }
  }

  .counter-box {
    &,
    .counter-box-header {
      color: $white;
    }
  }

  .counter-box-bold {
    .counter,
    .counter-box-header {
      color: $white;
    }
    .counter {
      color: $gray-4;
    }
  }

  .counter-box-minimal {
    .counter,
    .counter-box-title {
      color: $white;
    }
  }

  .quote-circle {
    cite,
    .quote-text,
    .quote-boxed-meta {
      color: $white;
    }
  }

  .list-nav-marked {
    > li {
      color: $white;
    }
  }

  .terms-list-inline {
    dt:after,
    dd {
      color: $manatee;
    }
  }

  .link {
    @include link(inherit, $brand-primary);
  }

  .owl-carousel {
    .owl-prev,
    .owl-next {

      &:hover {
        opacity: .5;
      }
    }

    .owl-prev {
      &,
      &:hover {
        border-color: transparent transparent $white $white;
      }
    }

    .owl-next {
      &,
      &:hover {
        border-color: $white $white transparent transparent;
      }
    }

    .owl-dot {
      border-color: $white;

      &.active {
        background: $white;
      }
    }
  }

  .owl-carousel-style-3 {
    .owl-prev,
    .owl-next {
      border-color: $brand-primary;
    }
  }

  .rd-mailform {
    .select2-container--bootstrap .select2-selection {
      color: $gray-darker;
      background: $white;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
      color: $gray-darker;
    }
  }

  .terms-list-uppercase {
    dd {
      color: $white;
    }
  }

  .row-custom-bordered-small {
    .col-bordered:after {
      border-color: rgba($white, .22);
    }
  }

  .marked-list {
    > li {
      color: $white;
    }
  }
  .pricing-box-2 .price-wrap > *, 
  .pricing-box-2 .price-wrap .price:before,
  .pricing-list-marked li {
    color: $white;
  }

  .pricing-box-3 {
    background-color: $white;
    .pricing-title {
      &:after {
        @include triangle(bottom, 28px, 15px, $white);
      }
    }
  }

  .page & {
    .icon-trout-outline {
      color: $white;
      border-color: $white;
    }
  }

  .isotope-filters {
    .inline-list {
      a {
        color: $white;

        &,
        &:active,
        &:focus {
          color: $white;
        }

        &:hover,
        &.active {
          color: $white;
        }
        
      }
    }
  }

  .btn-white-outline {
    &,
    &:active,
    &:focus {
      color: $white;
      background: transparent;
      border-color: $white;
    }

    &:hover {
      color: $white;
      background: $secondary-4;
      border-color: $secondary-4;
    }
  }
}

%context-light {
  color: $text-color;
  #{headings()} {
    color: $headings-color;
  }
}

.context-dark {
  @extend %context-dark;
}

.context-light {
  @extend %context-light;
}

.bg-default {
  @include bg-behaviour($white);
}

.bg-gray-dark {
  @extend %context-dark;
  @include bg-behaviour($gray-dark);

  &:not(.bg-image) .rd-mailform {
    .form-control {
      background: #444c50;
    }
  }
}

.bg-brand-info { 
  background-color: $brand-info;
}

.bg-gray-darken {
  @extend %context-dark;
  @include bg-behaviour($gray-darken);

  p {
    color: #b3bbd8;
  }
  .rd-mailform .form-control {
    background: #3f4f6e;
  }
  a.icon-gray-darker {
    color: $white;
    &:hover{
      color: $brand-success;
    }
  }
}

.bg-default-primary {
  @extend %context-dark;
  @include bg-behaviour($brand-primary);  

  .row-custom-bordered {
    [class*='col'] {
      border-color: rgba(#848e9c, 1);
    }
  }
}

.bg-secondary-3 {
  @extend %context-dark;
  @include bg-behaviour($secondary-3);

  .row-custom-bordered {
    [class*='col'] {
      border-color: rgba(#848e9c, 1);
    }
  }
  .height-fill {
    border-color: rgba($white, .11);
  }
}

.bg-gray-lighter {
  @include bg-behaviour($gray-lighter);
}
.bg-gray-light {
  @include bg-behaviour($gray-light);

  pre {
    background-color: $white;
  }

  .progress-variant-primary {
    .progress {
      background: $white;
    }
  }
  .progress-gradient {
    .progress {
      background: $white;
    }
  }

  .post-boxed {
    background: $white;
  }

  .thumbnail-boxed {
    background: $white;
  }

  .box-inset {
    background: $white;
  }

  .box-line {
    background: $white;
  }

  .box-triangle,
  .box-triangle-body,
  .box-triangle-body:before {
    background: $white;
  }

  .box-cta {
    background: $white;
  }

  .box-line {
    background: $white;
  }

  .quote-boxed-body,
  .quote-boxed-body:before {
    background: $white;
  }

  .box-form {
  }

  .form-control {
    background: $white;
  }

  .comment {
    .form-control {
      border: 2px solid $gray-1;
    }
  }

  .form-label {
    color: $gray-darker;
  }

  .search-form-classic .rd-search-submit {
    &:before {
      color: $gray-darker;
    }
    &:hover {
      &:before {
        color: $gray;
      }
    }
  }
  .pricing-box-3 {
    background-color: $white;
    .pricing-title {
      &:after {
        @include triangle(bottom, 28px, 15px, $white);
      }
    }
  }
}

.bg-blue-marguerite {
  @extend %context-dark;
  @include bg-behaviour($blue-marguerite);

  .counter-box-bold {
    .counter {
      color: #9b8ad3;
    }
  }

  &.row-custom-bordered,
  .row-custom-bordered {
    [class*='col'] {
      border-color: #9b8ad3;
    }
  }
}

.bg-gradient-primary {
  @extend %context-dark;
  background-color: #4060b6;
  background-image: linear-gradient(to right, rgba(#67d2ff, .27), rgba(#261b55, .27));
}

.bg-aside-left {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: inherit;
    width: 100vw;
  }
}

// Bg image
//

html {
  .bg-image {
    background-size: cover;
    background-position: center 55%;
    background-repeat: no-repeat;

    form[data-form-type='subscribe'] {
      .btn-primary {
        &,
        &:active,
        &:focus {
          color: $gray-darker;
          background: $white;
          border-color: $white;
        }

        &:hover {
          color: $white;
          background: $brand-primary;
          border-color: $brand-primary;
        }
      }
    }
  }
}


.bg-fixed {
  .desktop & {
    @media (min-width: $screen-md-min) {
      background-attachment: fixed;
    }
  }
}

// Bg decorated
//
.bg-decorated {
  position: relative;
  z-index: 10;
}

.bg-decoration {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 0;
  line-height: 0;

  &-top {
    top: 1px;
    transform: translateY(-100%);
  }

  &-bottom {
    bottom: 1px;
    transform: translateY(100%);
  }

  svg {
    min-width: 1000px;
    width: 100%;
    fill: $white;
  }
}