//
// RD Video BG
// --------------------------------------------------

// Custom Styles

.rd-video{
}

.vide_bg {
  z-index: 1;

  .container,
  .jumbotron-custom { 
    position: relative;
  }

  &:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: inline-block;
    background-color: rgba($mine-shaft, .5);
  }

  .text-success {
    color: $brand-success;
  }
  
  .swiper-container & {
    height: 100%; 
  }
}