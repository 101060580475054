//
// Pagination
// --------------------------------------------------
$pagination-size: 52px;
$pagination-offset: 20px;

.pagination-custom {
  position: relative;
  @include spacing(8px);
  line-height: 0;
  font-size: 0;
  text-align: center;

  li {
    display: inline-block;
    vertical-align: middle;
  }

  li a {
    display: block;
    width: auto;
    min-height: $pagination-size;
    min-width: $pagination-size;
    height: $pagination-size;
    padding: 10px 20px;
    border: 1px solid;
    font: 700 14px/24px $font-family-base;
    vertical-align: middle;

    &:after {
      content: '';
      height: 108%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &,
    &:active,
    &:focus {
      color: $pagination-color;
      background: $pagination-bg;
      border-color: #cdcdcd;
    }

    &:hover {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }

  li:first-child,
  li:last-child {
    a {
      font: 700 12px $font-family-base;
      text-transform: uppercase;
      letter-spacing: .2em;

    }
  }

  li:first-child a {
    &:before {
      content: 'Prev';
    }
  }

  li:last-child a {
    &:before {
      content: 'Next';
    }
  }

  li.disabled,
  li.active {
    pointer-events: none;
  }

  li.active a {
    color: $pagination-active-color;
    background: $pagination-active-bg;
    border-color: $pagination-active-border;
  }

  li.disabled a {
    color: $pagination-disabled-color;
    background: $pagination-disabled-bg;
    border-color: $pagination-disabled-border;
  }
}


* + .pagination-custom {
  margin-top: 35px;
}

@media (min-width: $screen-sm-min) {
  * + .pagination-custom {
    margin-top: 60px;
  }
}