//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  text-align: center;

  &:before {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Alternate icons
// --------------------------------------------------
.page {
  .icon-default {
    color: $text-color;
  }
  
  .icon-success {
    color: $brand-success;
  }

  .icon-gray {
    color: $gray;
  }
  
  .icon-gray-darker {
    color: $gray-darker;
  }

  .icon-primary {
    color: $brand-primary;
  }
  
  .icon-secondary {
    color: $secondary-2;  
  }
  
  .icon-white {
    color: $white;
  }
  .icon-gray-6 {
    color: $gray-6;
  }
  .icon-mine-shaft {
    color: $mine-shaft;
  }
  
  .icon-mako {
    color: $mako;
    
    &.icon-circle {
      background-color: $mako;
      color: $white;
    }
  }
  
  
  
  a.icon-white,
  a.icon-gray,
  a.icon-gray-darker,
  a.icon-gray-6,
  a.icon-mako,
  a.icon-mine-shaft {
    &:hover{
      color: $brand-success;   
    }

    &.icon-effect-1 {
      &:after {
        background: $white;
      }

      &:hover {
        border-color: transparent;
      }
    }

    &.icon-circle:hover {
      background-color: $brand-success;
      color: $white;  
    }
  }

  .icon-primary-filled {
    color: $white;
    background: $brand-primary;
  }

  .icon-gray-filled {
    color: $white;
    background: $gray;

    &:hover {
      color: $white;
      background: $brand-primary;
    }
  }

  .icon-gray-outline {
    color: $gray;
    background-color: transparent;
    border: 1px solid $gray;

    &.icon-effect-1 {
      &:after {
        background: $brand-primary;
      }

      &:hover {
        border-color: $brand-primary;
      }
    }
  }

  .icon-trout-outline {
    color: $trout;
    background-color: transparent;
    border: 1px solid $trout;

    &:hover {
      color: $white;
      background: $brand-primary;
      border-color: $brand-primary;
    }

    &.icon-effect-1 {
      &:after {
        background: $brand-primary;
      }

      &:hover {
        border-color: $brand-primary;
      }
    }
  }

  .icon-effect-1 {
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      top: -2px;
      left: -2px;
      padding: 2px;
      z-index: -1;
      transition: transform 0.2s, opacity 0.3s;
      @include transform (scale(1.3));
      opacity: 0;
    }

    &:hover {
      color: $white;
      background-color: transparent;
      &:after {
        @include transform (scale(1));
        opacity: 1;
      }
    }
  }
}

// Icon Sizes
// --------------------------------------------------

.icon-xxs {
  font-size: 12px;

  &.icon-circle {
    height: 27px;
    width: 27px;
    line-height: 27px;
  }
}

.icon-xs {
  font-size: 15px;

  &.icon-square {
    width: 37px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }
  
  &.icon-circle {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
}

.icon-sm {
  font-size: 20px;

  &.icon-circle {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &.icon-square {
    width: 42px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon-md {
  font-size: 22px;
}

.icon-lg {
}

.icon-xl {
}

// Icon shapes
// --------------------------------------------------
.icon-circle {
  border-radius: 100%;
  
  &.icon-effect-1 {
     &:after{
        border-radius: 50%;
     }
  }
}

.icon-square {
  border: 1px solid;
  
  &.fa-facebook {
    background-color: $steel-blue;
    &.icon-effect-1 {
      &:after {
        background: $steel-blue;
      }

      &:hover {
        border-color: $steel-blue;
      }
    }
    
  }
  &.fa-twitter {
    background-color: $picton-blue;
    &.icon-effect-1 {
      &:after {
        background: $picton-blue;
      }

      &:hover {
        border-color: $picton-blue;
      }
    }
  }
  &.fa-pinterest {
    background-color: $mandy;
    &.icon-effect-1 {
      &:after {
        background: $mandy;
      }

      &:hover {
        border-color: $mandy;
      }
    }
  }
  &.fa-instagram {
    background-color: #398aaa;

    &.icon-effect-1 {
      &:after {
        background: #398aaa;
      }

      &:hover {
        border-color: #398aaa;
      }
    }
  }

  &.fa-facebook,
  &.fa-twitter,
  &.fa-pinterest,
  &.fa-instagram {
    color: $white;  
  }
}

// Other
// --------------------------------------------------
.fa-google-plus {
  position: relative;
  left: -1px;
}