// Custom CSS Vendors

@mixin transform($trfm) {
  -webkit-transform: $trfm;
  transform: $trfm;
}

@mixin transform-origin($origin) {
  transform-origin: $origin;
}

@mixin box-shadow($box-shadow) {
  box-shadow: $box-shadow;
}

@mixin animation($anim) {
  -webkit-animation: $anim;
  animation: $anim;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} { 
    @content;
  }
}

@mixin sprite-animation() {
  @include keyframes(sprite-animation) {
    from {
      background-position: 0 0;
    }
  }
}

@mixin responsive-offset($offset) {
  position: relative;
  transform: translateY(-$offset);
  margin-bottom: -$offset;
  pointer-events: none;

  > *:before {
    content: '';
    display: table;
    width: 0;
  }

  > * {
    margin-top: $offset;
    pointer-events: auto;
  }
}
@mixin make-grid-system($resolution, $cell-count) {
  .range-#{$resolution}-center {
    @include justify-content(center);
  }

  .range-#{$resolution}-left {
    @include justify-content(flex-start);
  }

  .range-#{$resolution}-right {
    @include justify-content(flex-end);
  }

  .range-#{$resolution}-justify {
    @include justify-content(space-between);
  }

  .range-#{$resolution}-around {
    @include justify-content(space-around);
  }

  .range-#{$resolution}-top {
    @include align-items(flex-start);
  }

  .range-#{$resolution} {
    @include flex-direction(row);
  }

  .range-#{$resolution}-reverse {
    @include flex-direction(row-reverse);
  }

  .range-#{$resolution}-middle {
    @include align-items(center);
  }

  .range-#{$resolution}-bottom {
    @include align-items(flex-end);
  }

  .cell-#{$resolution}-top {
    @include align-self(flex-start);
  }

  .cell-#{$resolution}-middle {
    @include align-self(center);
  }

  .cell-#{$resolution}-bottom {
    @include align-self(flex-end);
  }

  .range > {
    @include make-cell($resolution, $cell-count);
  }
}