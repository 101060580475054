//
// Layouts
// --------------------------------------------------

// Footer default
//

.footer-default {
  background-color: $gray-7;
  color: #b3bbd8;
  
  .icon {
    color: #b3bbd8; 
    &:hover{
      color: $brand-primary;   
    }
  }
}

.pre-footer-default {
  padding: 50px 0;
  background: $white;

  * + .rd-mailform {
    margin-top: 20px;
  }
  h4 {
    font-weight: 700;
  }
}

.bg-white + .pre-footer-default {
  border-top: 1px solid $hr-border;
}

.page-footer-default {
  position: relative;
  overflow: hidden;
  color: $manatee;
  background: $gray-dark;
  padding: 30px 0;
  
  .pre-footer-default  + & {
    margin-top: 0;
  }

  a {
    @include link(inherit, $white);
  }
}

@media (max-width: $screen-xs-max) {
  .pre-footer-default {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-md-min) {
  .pre-footer-header {
    margin-top: 10px;
  }

  .page-footer-default {
    padding: 10px 0;
  }
}

// Footer minimal
//
.pre-footer-minimal {
  h4 + *,
  .h4 + * {
    margin-top: 16px;
  }
}

.pre-footer-minimal-inner {
  padding: 37px 0;
}

.pre-footer-minimal,
.page-footer-minimal {
  
  & + & {
    margin-top: 0;
  }
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max){
    font-size: 13px;

    [class*= 'list'] li {
      font-size: 12px;
    }
  }
}

.page-footer-minimal {
  position: relative;
  overflow: hidden;
  color: $manatee;
  background: $gray-dark;
  padding: 30px 0;
  text-align: center;

  a {
    @include link(inherit, $white);
  }
}

@media (min-width: $screen-md-min) {
  .page-footer-minimal {
    padding: 16px 0;
  }
}

// Footer corporate
//
.page-footer-corporate {
  position: relative;
  z-index: 1;
  padding: 1px 0;
  color: $manatee;
  background-color: transparent;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: $gray-dark;
    pointer-events: none;
  }

  @media (min-width: $screen-md-min) {
    &:before {
      top: 0;
    }
  }

  > * {
    z-index: 2;
  }

  a {
    @include link(inherit, $white);
  }

  h3,
  .h3 {
    text-transform: none;
  }

  .rd-mailform + * {
    margin-top: 40px;
  }
}

.page-footer-corporate-inner {
  position: relative;
}

.page-footer-corporate-top {
  padding: 40px 0;
}

.page-footer-corporate-bottom {
  padding: 20px 0;
  @include spacing(10px);
  text-align: center;
}

@media (max-width: $screen-xs-max) {
  .page-footer-corporate-inner {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-sm-min) {
  .page-footer-corporate-bottom {
    @include display-flex;
    @include flex-direction(row-reverse);
    @include flex-wrap(wrap);
    @include align-items(center);
    @include justify-content(space-between);
  }
}

@media (min-width: $screen-xl-min) {
  .page-footer-corporate {
    .rd-mailform + * {
      margin-top: 70px;
    }
  }

  .page-footer-corporate-top {
    padding: 70px 0 40px;
  }

  .page-footer-corporate-inner {
    padding: 0 50px 0 35px;
  }
}

// One screen page
//
.one-screen-page {
  text-align: center;
  background-color: #1b181d;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  @extend %context-dark;

  .page {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(stretch);
    background-color: transparent;
  }

  &-inner {
    @include display-flex;
    @include flex-direction(column);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(space-between);
    min-height: 100vh;
    @include flex-grow(1);
    &,
    > * {
      width: 100%;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
    }
  }

  .shell {
    width: 100%;
  }

  header {
    padding: calc(1em + 3vh) 0 calc(1em + 1.5vh);
  }

  .one-screen-page-content {
    padding: calc(1em + 3vh) 0;
  }

  footer {
    padding: calc(1em + 1.5vh) 0 calc(1em + 3vh);
  }

  .rights {
    a {
      @include link($white, $silver);
    }
  }

  * + .group-sm {
    margin-top: 35px;
  }

  @media (min-width: $screen-sm-min) {
    * + .group-sm {
      margin-top: 45px;
    }

    * + .h7 {
      margin-top: 40px;
    }
  }
}

.ie-10,
.ie-11 {
  .one-screen-page {
    overflow-x: hidden;
    overflow-y: auto;
  }
}