//
// Wrappers
// --------------------------------------------------
.object-wrap {
  position: relative;
  overflow: hidden;
}

@media (min-width: $screen-md-min) {
  .object-wrap-md-right {
    > .object-wrap-body {
      right: 0;
    }
  }

  .object-wrap-md-left {
    > .object-wrap-body {
      left: 0;
    }
  }
}

@media (min-width: $screen-md-min) {
  .object-wrap-body {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1px;
    max-width: none;
    height: 100%;
    min-height: 100%;
    max-height: none;
    margin: 0;
    background: inherit;
    z-index: 0;

    & + * {
      margin-top: 0;
    }
  }

  .object-wrap-body.object-wrap-map {
    @include display-flex;

    .google-map {
      width: 100%;
      height: 100%;
      @include display-flex;
    }
  }
}

// sizes
.sizing-1 {
  @media (min-width: $screen-md-min) {
    @include make-grid-parameter(width, -50px, $shell-md-width, 6);
  }

  @media (min-width: $screen-lg-min) {
    @include make-grid-parameter(width, 0, $shell-lg-width, 5);
  }

  @media (min-width: $screen-lg-min) {
    @include make-grid-parameter(width, 0, $shell-xl-width, 5);
  }
}

