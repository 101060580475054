// Unit Utilities
@mixin unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint){
  //.unit#{$breakpoint}-align-center {
  //  @include justify-content(center);
  //}
  //
  //.unit#{$breakpoint}-align-left {
  //  @include justify-content(flex-start);
  //}
  //
  //.unit#{$breakpoint}-align-right {
  //  @include justify-content(flex-end);
  //}
  //
  //.unit#{$breakpoint}-align-justify {
  //  @include justify-content(space-between);
  //}
  //
  //.unit#{$breakpoint}-align-around {
  //  @include justify-content(space-around);
  //}
  //
  //.unit#{$breakpoint}-top{
  //  @include align-items(flex-start);
  //}

  .unit#{$breakpoint}-middle{
    @include align-items(center);
  }
  //
  //.unit#{$breakpoint}-bottom{
  //  @include align-items(flex-end);
  //}
  //
  //.unit#{$breakpoint}-grow-1{
  //  @include flex-grow(1);
  //}
  //
  //.unit#{$breakpoint}-grow-2{
  //  @include flex-grow(2);
  //}
  //
  //.unit#{$breakpoint}-grow-3{
  //  @include flex-grow(3);
  //}
  //
  //.unit-item#{$breakpoint}-top{
  //  @include align-self(flex-start);
  //}
  //
  //.unit-item#{$breakpoint}-middle{
  //  @include align-self(center);
  //}
  //
  //.unit-item#{$breakpoint}-bottom{
  //  @include align-self(flex-end);
  //}
  //.unit-item#{$breakpoint}-narrow{
  //  @include flex-grow(0);
  //}
  //
  //.unit-item#{$breakpoint}-standart{
  //  @include flex-grow(1);
  //}
  //
  //.unit-item#{$breakpoint}-wide{
  //  @include flex-grow(2);
  //}

  .unit#{$breakpoint},
  .unit#{$breakpoint}-vertical {
    @include flex-direction(column);

    > [class*='unit-']:first-child {
      padding-top: 0;
    }

    > .unit-left + .unit-right,
    > .unit-left + .unit-body {
      padding-left: 0;
      padding-top: $spacing-t;
    }

    > .unit-body + .unit-right {
      padding-left: 0;
      padding-top: $spacing-b;
    }
  }

  .unit#{$breakpoint}-horizontal {
    @include flex-direction(row);

    > .unit-left + .unit-right,
    > .unit-left + .unit-body {
      padding-top: 0;
      padding-left: $spacing-l;
    }

    > .unit-body + .unit-right {
      padding-top: 0;
      padding-left: $spacing-r;
    }
  }

  //.unit#{$breakpoint}-inverse {
  //  @include flex-direction(column-reverse);
  //
  //  &,
  //  &.unit#{$breakpoint}-vertical {
  //    @include flex-direction(column-reverse);
  //
  //    > [class*='unit-']:first-child {
  //      padding-top: $spacing-t;
  //    }
  //
  //    > [class*='unit-']:last-child {
  //      padding-top: 0;
  //    }
  //  }
  //
  //  &.unit#{$breakpoint}-horizontal {
  //    @include flex-direction(row-reverse);
  //
  //    > [class*='unit-']:first-child {
  //      padding-top: 0;
  //      padding-left: $spacing-l;
  //    }
  //
  //    > [class*='unit-']:last-child {
  //      padding-left: 0;
  //    }
  //  }
  //}
}

@mixin unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint){
  &.unit#{$breakpoint},
  &.unit#{$breakpoint}-vertical {
    > [class*='unit-']:first-child {
      padding-top: 0;
    }

    > .unit-left + .unit-right,
    > .unit-left + .unit-body {
      padding-left: 0;
      padding-top: $spacing-t;
    }

    > .unit-body + .unit-right {
      padding-left: 0;
      padding-top: $spacing-b;
    }
  }

  &.unit#{$breakpoint}-horizontal {
    > .unit-left + .unit-right,
    > .unit-left + .unit-body {
      padding-top: 0;
      padding-left: $spacing-l;
    }

    > .unit-body + .unit-right {
      padding-top: 0;
      padding-left: $spacing-r;
    }
  }

  //&.unit#{$breakpoint}-inverse {
  //  &,
  //  &.unit#{$breakpoint}-vertical {
  //    > [class*='unit-']:first-child {
  //      padding-top: $spacing-t;
  //    }
  //
  //    > [class*='unit-']:last-child {
  //      padding-top: 0;
  //    }
  //  }
  //
  //  &.unit#{$breakpoint}-horizontal {
  //    > [class*='unit-']:first-child {
  //      padding-top: 0;
  //      padding-left: $spacing-l;
  //    }
  //
  //    > [class*='unit-']:last-child {
  //      padding-left: 0;
  //    }
  //  }
  //}
}

@mixin unit-spacing($medias, $spacing-t, $spacing-r:$spacing-t, $spacing-b:$spacing-t, $spacing-l:$spacing-r){
  @each $resolution, $alias in $medias {
    @if ($alias != '' and $alias != null) {
      $breakpoint: -#{$alias};
      @media (min-width: $resolution) {
        @include unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
      }
    } @else {
      $breakpoint: '';
      @include unit-spacing-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
    }
  }
}

@mixin unit-responsive($medias, $spacing-t, $spacing-r:$spacing-t, $spacing-b:$spacing-t, $spacing-l:$spacing-r) {
  .unit {
    @include display-flex();
    @include flex(0 1 100%);
    @include flex-direction(column);
  }

  .unit-body{
    @include flex(0 1 auto);
  }

  .unit-left,
  .unit-right{
    @include flex(0 0 auto);
    max-width: 100%;
  }

  @each $resolution, $alias in $medias {
    @if ($alias != '' and $alias != null) {
      $breakpoint: -#{$alias};
      @media (min-width: $resolution) {
        @include unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
      }
    } @else {
      $breakpoint: '';
      @include unit-variant($spacing-t, $spacing-r, $spacing-b, $spacing-l, $breakpoint);
    }
  }
}