//
// Jumbotron custom
// --------------------------------------------------
.jumbotron-custom {
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(center);
  @include justify-content(center);
  padding-left: 15px;
  padding-right: 15px;

  .shell {
    width: 100%;
  }

  * + .h3-alternate {
    margin-top: 5px;
  }

  &-inner > * {
    pointer-events: auto;
  }
}

.jumbotron-custom-variant-1 {
  perspective: 1600px;

  .mark {
    padding: .2em .22em .12em;
  }

  * + .btn {
    margin-top: 30px;
  }

  hr,
  p {
    display: none;
  }

  @media (min-width: $screen-sm-min) {
    hr,
    p {
      display: block;
    }

    * + h1 {
      margin-top: 30px;
    }
  }
}

.jumbotron-custom-variant-2 {
  min-height: calc(40vw - 56px);
  text-align: left;

  .mark {
    padding: .4em .6em .4em;
  }
  * + .btn {
    margin-top: 25px;
  }
}

.jumbotron-custom-variant-3 {
  min-height: calc(44.27vw - 56px);
  text-align: left;

  .mark {
    padding: .3em .8em .14em;
  }

  * + .rd-mailform {
    margin-top: 5px;
  }

  * + [class*='group'] {
    margin-top: 10px;
  }
}

.ie-10,
.ie-11 {

  .jumbotron-custom.jumbotron-custom-variant-1 {
    min-height: 0;
  }

  .jumbotron-custom-variant-2,
  .jumbotron-custom-variant-3 {
    .shell {
      text-align: left;
    }
  }
}

.jumbotron-custom-variant-1 {
  .jumbotron-custom-inner {
    padding: 70px 0 85px;
  }
}

.jumbotron-custom-variant-2 {
  .jumbotron-custom-inner {
    padding: 55px 0 70px;
  }

  #{headings()} {
    color: $gray-dark;
  }

  .mark {
    display: inline-block;
    color: $white;
  }
}

.jumbotron-custom-variant-3 {
  .jumbotron-custom-inner {
    padding: 50px 0 80px;
  }
}

@media (max-width: $screen-xs-max) {
  .jumbotron-custom-variant-3 {
    h1,
    .h1 {
      font-size: 40px;
    }

    .rd-mailform .select2-container--bootstrap .select2-selection {
      min-width: 220px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .jumbotron-custom-variant-1 {


    .jumbotron-custom-inner {
      display: inline-block;
      padding: 150px 0 160px;
      text-align: left;
    }
  }

  .jumbotron-custom-variant-2 {
    .jumbotron-custom-inner {
      padding: 90px 0 130px;
    }
  }

  .jumbotron-custom-variant-3 {
    .jumbotron-custom-inner {
      padding: 170px 0 170px;
    }

    * + [class*='group'] {
      margin-top: 25px;
    }
  }
}

.rd-navbar-sidebar-linked {
  @media (min-width: $screen-md-min) {
    .jumbotron-custom-variant-3 {
      .jumbotron-custom-inner {
        padding: 270px 0 170px;
      }
    }
  }
}

.desktop {

  @media (min-width: $screen-md-min) {
    // Jumbotron 1
    .jumbotron-custom-variant-1 {
      //min-height: 44.27vw;
      height: 100%;
    }

    .rd-navbar-absolute-linked.jumbotron-custom-variant-1 .jumbotron-custom-inner {
      padding: 330px 0 220px;
    }

    // Jumbotron 2
    .jumbotron-custom-variant-2 {
      //min-height: 40.27vw;
      height: 100%;
    }

    .jumbotron-custom-variant-2 .jumbotron-custom-inner {
      padding: 140px 0 145px;
    }

    // Jumbotron 3
    .jumbotron-custom-variant-3 {
      //min-height: 44.27vw;
      height: 100%;
    }

    .jumbotron-custom-variant-3 .jumbotron-custom-inner {
      padding: 260px 0 220px;
    }
  }

  @media (min-width: $screen-lg-min) {
    //1
    .jumbotron-custom-variant-1 {
      .jumbotron-custom-inner {
        padding: 260px 0 180px;
      }
    }
    // 2
    .jumbotron-custom-variant-2 .jumbotron-custom-inner {
      padding: 140px 0 150px;
    }
  }

  @media (min-width: $screen-xl-min) {
    // 1
    .jumbotron-custom-variant-1 {
      //min-height: 100vh;
    }

    .rd-navbar-absolute-linked .jumbotron-custom-variant-1 .jumbotron-custom-inner {
      padding: 280px 0 290px;
    }

    // 2
    .jumbotron-custom-variant-2 {
      //min-height: 795px;
    }

    .jumbotron-custom-variant-2 .jumbotron-custom-inner {
      padding: 100px 0 250px;
    }

    // 3
    .jumbotron-custom-variant-3 .jumbotron-custom-inner {
      padding: 200px 0 250px;
    }
  }

}

@media (min-width: $screen-md-min) {
  .rd-navbar-static-linked {
    .jumbotron-custom-variant-1 .jumbotron-custom-inner {
      padding-top: 260px;
      padding-bottom: 124px;
    }

    .jumbotron-custom-variant-2 .jumbotron-custom-inner {
      padding-top: 200px;
      padding-bottom: 140px;
    }

    .jumbotron-custom-variant-3 .jumbotron-custom-inner {
      padding-top: 220px;
      padding-bottom: 180px;
    }
  }

  .rd-navbar-fullwidth-linked {
    .jumbotron-custom-variant-1,
    .jumbotron-custom-variant-3 {
      min-height: 38vw;
    }
  }

  .rd-navbar-sidebar-linked {
    .jumbotron-custom-variant-2 {
      .jumbotron-custom-inner {
        padding: 220px 0 180px;
      }
    }
  }
}


.swiper-jumbotron-mod {
  @media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
    .jumbotron-custom-variant-1 {
      min-height: 34.27vw;
    }
    .jumbotron-custom {
      justify-content: flex-end;
    }
  }
  @media (min-height: 600px) and (max-height: 800px) {
    .jumbotron-custom-variant-1 {
      min-height: 34.27vw;
    }
    .jumbotron-custom {
      justify-content: flex-end;
    }
  }
}