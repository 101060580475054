
/*
*
* Material Parallax
* --------------------------------------------------
*/

.parallax-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  .container + & {
    margin-top: 30px;
  }
}

.material-parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translate3d(0, 0, 0);
  transform: translateX(-50%)
}