//
// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

// Unit Spacing
//

.unit-spacing-xs {
  @include unit-spacing($medias, 7px, 15px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 15px, 19px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 20px, 30px);
}