/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
  
  [data-nav='true'] &{
    
  }
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: transform;
}

.owl-carousel.pricing-carousel{
  .owl-item{
    will-change: inherit;
  }
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before {
    content: '\f144';
  }
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
  
  &.disabled {
    display: none;
  }
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  font-size: 0;
  line-height: 0;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-style: solid;
  border-color: $brand-primary;
  background-color: transparent;
  transition: .33s all ease;
  cursor: pointer;
}

.owl-prev {
  left: -5px;
  border-width: 0 0 1px 1px;

  &:hover {
    border-color: transparent transparent $brand-success $brand-success;
  }
}

.owl-next {
  right: -5px;
  border-width: 1px 1px 0 0;

  &:hover {
    border-color: $brand-success $brand-success transparent transparent;
  }
}

/*
 * Owl Pagination
 */

.owl-dots {
  text-align: center;
  margin-top: 35px;
  
  &.disabled {
    display: none;
  }
}

.owl-dot {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-left: 10px;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  background: transparent;
  border: 2px solid;

  &:hover,
  &.active {
    background: $brand-primary;
    color: $brand-primary;
  }

  &.active {
    pointer-events: none;
  }
}

.owl-style-1 {
  .item {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }

  .owl-next,
  .owl-prev {
    display: none !important;
  }
}

* + .owl-style-1 {
  margin-top: 45px;
}
h3 + .owl-style-1 {
  margin-top: 40px;
}

@media (min-width: $screen-md-min) {
  .owl-style-1 {
    .owl-next,
    .owl-prev {
      display: block !important;
    }
  }

  * + .owl-style-1 {
    margin-top: 65px;
  }
}

@media (min-width: $screen-lg-min) {
  .owl-style-1 {
    padding: 0 45px;
  }

  * + .owl-style-1 {
    margin-top: 95px;
  }
}

[data-animation-in='fadeIn'][data-animation-out='fadeOut'] {
  .owl-item {
    &.active {
      .owl-content {
        opacity: 1;
        transition-delay: .3s;
      }
    }

    .owl-content {
      opacity: 0;
      transition: .7s;
      transition-delay: 0s;
    }
  }
}

// Style: tiny
.owl-style-tiny {
  .owl-dots {
    margin-top: 18px;
  }
}

@media (max-width: $screen-sm-max) {
  .owl-style-tiny {
    .owl-prev,
    .owl-next {
      display: none !important;
    }
  }
}

@media (min-width: $screen-md-min) {
  .owl-style-tiny {
    .owl-carousel {
      padding: 0 30px;
    }

    .owl-dots {
      display: none !important;
    }

    .owl-prev {
      left: -10px;
    }

    .owl-next {
      right: -10px;
    }
  }

}

@media (min-width: $screen-lg-min) {
  .owl-style-tiny {
    .owl-prev {
      left: -5px;
    }

    .owl-next {
      right: -5px;
    }
  }

  .desktop {
    .owl-style-tiny {
      width: calc(100% + 30px);
      max-width: none;
      margin: -30px -15px;

      .owl-carousel {
        padding: 0;
      }

      .owl-stage-outer {
        padding: 45px;
        margin: 0 30px;
      }

      .owl-stage {
        left: -56px;
      }

      .owl-item .item {
        padding-right: 20px;
        margin-left: -20px;
      }

      .owl-item.active .item {
        margin-left: 0;
        padding-left: 20px;
      }

      .owl-item.active + .owl-item {
        margin-left: 20px;
      }

      .owl-item.active + .owl-item.active {
        margin-left: -20px;
      }
    }
  }
}

@media (min-width: $screen-xl-min) {
  .owl-style-tiny {
    .owl-prev,
    .owl-next {
      display: none !important;
    }
  }

  .desktop {
    .owl-style-tiny {
      width: calc(100% + 120px);
      margin: -30px -60px;
      padding: 0;

      .owl-stage {
        left: -45px;
      }

      .owl-item .item {
        padding-right: 30px;
        margin-left: -15px;
      }

      .owl-item.active .item {
        margin-left: 0;
        padding-left: 30px;
      }

      .owl-item.active + .owl-item {
        margin-left: 45px;
      }

      .owl-item.active + .owl-item.active {
        margin-left: -30px;
      }
    }
  }
}

// Style: centered
.owl-carousel-centered {
  $value: 80px;
  text-align: center;

  .owl-item {
    position: relative;
    z-index: 2;
    overflow: visible;

    img {
      width: 100%;
      height: auto;
    }
  }

  .owl-prev,
  .owl-next {
    width: auto;
    height: auto;
    border: 0;
    transform: none;
    top: auto;
    bottom: -47px;
    color: #b9bcce;

    &:before {
      font-family: 'Material Icons';
      font-size: 20px;
    }

    &:hover {
      color: $brand-primary;
    }
  }

  .owl-prev {
    left: calc(50% - #{$value});
    &:before {
      content: '\e5c4';
    }
  }

  .owl-next {
    right: calc(50% - #{$value});
    &:before {
      content: '\e5c8';
    }
  }

  * + .owl-numbering {
    margin-top: 20px;
  }

  @media (min-width: $screen-sm-min) {
    .owl-stage-outer {
      padding: 50px 0;
      margin: -50px 0 -50px 0;
      width: calc(100% + 10px);
    }

    .owl-item > * {
      position: relative;
      transform: scale(1);
      transition: .33s all ease;
      z-index: 10;
      opacity: .3;
    }

    .owl-item.center {
      z-index: 3;
      > * {
        opacity: 1;
        transform: scale(1.16);
        //box-shadow: -7px 9px 27px 0px rgba(#6b7f8e, 0.27);
      }
    }

    .owl-prev,
    .owl-next {
      bottom: -26px;
    }

    * + .owl-numbering {
      margin-top: 48px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .owl-stage-outer {
      padding: 60px 0;
    }
  }
}

// Numbering
//

.owl-numbering {
  margin-top: 20px;
  font-size: 0;
  line-height: 0;
  > * {
    display: inline-block;
    min-width: 16px;
  }

  .numbering-separator {
    min-width: 10px;
    margin: 0 -6px;
  }
}

.numbering-separator {
  &:after {
    content: '\2216';
  }
}

.owl-numbering-default {
  font: 400 24px $font-family-sec;
  color: $gray-dark;
  letter-spacing: -.025em;
}

.owl-carousel-gallery {
  
  * + & {
    margin-top: 45px;
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    height: 68px;
    width: 39px;
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(center);
    background: $white;
    color: $gray-dark;
    z-index: 2;
    text-align: center;
    transition: .33s all ease;
    cursor: pointer;
    border:0;

    &:before {
      color: inherit;
      font-family: 'Material Icons';
      font-size: 20px;
      line-height: 1;
      transition: none;
    }

    &:hover {
      width: 68px;
      color: $white;
      background: $brand-primary;

      &:active {
        background: $puerto-rico;
      }
    }
  }

  .owl-prev {
    left: 0;
    &:before {
      content: '\e5c4';
    }
  }

  .owl-next {
    right: 0;
    &:before {
      content: '\e5c8';
    }
  }

  &__mod-1{
    @media (min-width: $screen-lg-min) {
      padding-left: 73px;
      padding-right: 73px;

      .owl-prev,
      .owl-next {
        background: rgba($black, .27);
        color: $white;
      }
    }
  }
}

//style 2
.owl-carousel-style-2 {
  .owl-item {
    opacity: .37;
    transition: opacity .33s;
    will-change: opacity;
    
    &.active {
      opacity: 1;
    }
  }
  
  * + & {
    margin-top: 40px;
  }
}

//style 3
.owl-carousel-style-3 {
  .owl-stage {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .owl-prev,
  .owl-next {
    border-width: 1px;
    border-color: $brand-primary;
    background-color: $brand-primary;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    
    display: flex;
    align-items:center;
    justify-content: center;
    
    &:before{
      position: relative;
      display: inline-block;
      font: 400 24px/9px "FontAwesome";
      color: $white;
    }
    
    &:hover{
      border-color: $brand-success;
      background-color: $brand-success;
    }
  }

  .owl-prev {
    &:before {
      left: -1px;
      content: '\f104';
    }
  }
  .owl-next {
    &:before {
      right: -1px;
      content: '\f105';
    }
  }

  @media (min-width: $screen-md-min) {
    .owl-prev {
      left: 4px;
    }
    .owl-next {
      right: 5px;
    }
  }
}

// style 4
.owl-carousel.owl-carousel-style-4 {
  display: flex;
  flex-direction: column-reverse;
  
  > * {
    flex-grow: 1;
    width: 100%;
  }

  .owl-nav {
    display: flex;
    justify-content: flex-end;

    margin-top: 5px;
    margin-bottom: 5px;
  }

  .owl-prev,
  .owl-next {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    transform: none;

    border-width: 1px;
    border-color: $brand-primary;
    background-color: $brand-primary;
    width: 40px;
    height: 40px;

    display: flex;
    align-items:center;
    justify-content: center;

    &:before{
      position: relative;
      display: inline-block;
      font: 400 24px/9px "FontAwesome";
      color: $white;
    }

    &:hover{
      border-color: $brand-success;
      background-color: $brand-success;
    }
  }

  .owl-prev {
    &:before{
      left: -1px;
      content: '\f104';
    }
  }
  .owl-next {
    margin-left: 5px;
    &:before{
      right: -1px;
      content: '\f105';
    }
  }
  
}