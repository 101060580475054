//
// Grid modules
// --------------------------------------------------
.row-no-gutter {
  margin-left: 0;
  margin-right: 0;

  > [class*='col'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.row-gutter-custom {
  @media (max-width: $screen-md-max) {
    margin-left: -8px;
    margin-right: -8px;

    > [class*='col'] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.page .shell-custom-width {
  max-width: 1715px;
}

.container {
  min-width: 320px; 
}

.grid-element {
  padding: 12px 8px;
  font-weight: 400;
  letter-spacing: 0;
  //text-align: left;

  @media (max-width: $screen-md-max) {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.container-fullwidth {
  .grid-element {
    @media (min-width: $screen-sm-min) {
      padding: 15px 10px;
    }

    @media (min-width: $screen-md-min) {
      padding: 25px 15px;
    }

    @media (min-width: $screen-lg-min) {
      padding: 30px 15px 30px 30px;
    }

    @media (min-width: 1400px) {
      padding: 40px 70px;
    }
  }
}

.grid-system-bordered {
  .grid-system-row {
    border-bottom: 1px solid $gray-light;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.grid-system-bordered {
  .grid-element {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media (min-width: $screen-sm-min) {
    .grid-element {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .grid-element {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
}

.grid-system-outline {
  .grid-system-row {
    border: 1px solid $gray-light;
    border-width: 1px 1px 0 1px;

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  [class*='col']:not(:last-child) {
    .grid-element {
      border-right: 1px solid $gray-light;
    }

    margin-right: -1px;
  }

  [class*='col']:not(:first-child) {
    .grid-element {
      border-left: 1px solid $gray-light;
    }
  }
}

* + .grid-system-row {
  margin-top: 45px;
}

.grid-system-row + .grid-system-row {
  margin-top: 0;
}

// Range bordered
//
.row-custom-bordered {
  [class*='col'] {
    border-style: solid;
    border-color: #e8e8e8;
    border-width: 0;
  }

  [class*='col']:nth-child(2n) {
    border-width: 0 0 0 1px;
  }
  
  .grid-element {
    position: relative;
    &:after{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      border-top: 1px solid $gray-1;
      width: 90%;
      opacity: 0;
    }
  }

  @media (min-width: $screen-xs-extra) {

    [class*='col']:nth-child(n + 2) {
      border-width: 0 0 0 1px;
    }
  }

  @media (min-width: $screen-sm-min) {
    .grid-element {
      &:nth-child(n + 2) {
        border-width: 0;
      }
      
      &:nth-child(n + 3) {
         &:after{
           opacity: 1;
         }
      }
      
      &:nth-child(even) {
        border-left: 1px solid $gray-1;
      }
    }
    .col-sm-3 {
      &:first-child {
        border-width: 0 1px 0 0;
      }

      &:nth-child(n + 2) {
        border-width: 0 1px 0 0;
      }
      
      &:last-of-type {
        border-width: 0 ;
      }
    }

    .col-sm-4 {
      &:nth-child(n) {
        border-width: 0;
      }

      &:nth-child(n + 2) {
        border-width: 0 0 0 1px;
      }
    }
  }
  @media (min-width: $screen-md-min) {
    .grid-element {
      &:nth-child(n + 2) {
        border-width: 0;
        &:after{
          opacity: 0;
        }
      }

      &:nth-child(n + 4) {
        &:after{
          opacity: 1;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6) {
        border-left: 1px solid $gray-1;
      }
    }
  }
}

.row-custom-bordered-small {
  .col-bordered {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: -15px;
      right: 7px;
      left: 7px;
      bottom: 0;
      z-index: -1;
      border-style: solid;
      border-color: #e8e8e8;
      border-width: 0;
      pointer-events: none;
    }
  }

  [class*='col'] {
    margin-top: 0;
  }

  [class*='col'].col-bordered:nth-child(n + 2):after {
    border-width: 1px 0 0 0;
  }

  @media (min-width: $screen-xs-min) {
    .col-bordered:after {
      top: 0;
      left: 0;
      right: 0;
    }

    .col-bordered.col-6 {
      &:nth-child(odd):after {
        border-width: 0 1px 1px 0;
      }

      &:nth-child(even):after {
        border-width: 0 0 1px 0;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .col-bordered:after {
      height: 107px;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }

    .col-bordered.col-sm-3 {
      &:first-child:after {
        border-width: 0 1px 0 1px;
      }

      &:nth-child(n + 2):after {
        border-width: 0 1px 0 0;
      }
    }

    .col-bordered.col-sm-4 {
      &:nth-child(n):after {
        border-width: 0;
      }

      &:nth-child(n + 2):after {
        border-width: 0 0 0 1px;
      }
    }
  }
}

.row-custom-bordered-mod {
  @media (min-width: $screen-xl-min) {
    padding-right: 40px;
  }
}

.row-custom-bordered-mod-2 {
  margin: 0 -15px;
}

.row-custom-bordered-modern {
  [class*='col-'] {
    & + [class*='col-'] {
      
      .box-minimal  {
        border-top:1px solid $gray-1;
      }
    }   
  }

  @media (min-width: $screen-xs-extra-min) {
    [class*='col-']:nth-child(2) .box-minimal {
      border-top: 0;
    }
    
    [class*='col-'] .box-minimal {
      margin-top: -15px;
    }    
    
    [class*='col-']:nth-child(even) {
      border-left: 1px solid $gray-1;
    }
  }
  
  @media (min-width: $screen-md-min) {
    margin-left: -30px;
    margin-right: -30px;
    
    [class*='col-'] {
      &:nth-child(odd) {
        padding-left: 30px;
        padding-right: 15px;
      }
      &:nth-child(even) {
        .box-minimal {
          padding-left: 15px;
        }
      }
      .box-minimal {
        padding-bottom: 25px;
      }
    }

    .col-lg-4 {
      border-left: 1px solid $gray-1;
      
      &:nth-child(3) .box-minimal {
        border-top: 0;
      }
      
      &:nth-child(1),
      &:nth-child(4) {
        border-left: 0;
      }
    }
  }
  
}

// Flickrfeed
//

.pre-footer-default {
  .row.flickr {
    margin-left: -5px;
    margin-right: -5px;
    width: 100%;
    max-width: 315px;

    [class*='col'] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  * + .row.flickr {
    margin-top: 14px;
  }
}
