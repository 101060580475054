/**
* Template Style
*
* [Table of contents]
*   1 Bootstrap Toolkit Styles
*     1.1  Reset
*     1.2  Flex Grid System
*     1.3  Responsive Text System
*     1.4  Responsive Pulls System
*     1.5  Responsive Visibility System
*     1.6  Type Addons
*     1.7  Alerts Addons
*     1.8  Text Styling
*     1.9  Icons
*     1.10 Font Awesome
*     1.11 Material Design Icons
*     1.12 Thumbnail Addons
*     1.13 Form Addons
*     1.14 Responsive Units
*     1.15 Sections
*     1.16 Scaffolding Addons
*     1.17 Offsets
*
*   2 Plugins
*     2.1  Animate
*     2.2  Isotope
*     2.3  Owl Carousel
*     2.4  RD Navbar
*     2.5  RD Parallax
*     2.6  RD Google Maps
*     2.7  Swiper
*     2.8  UI-to-top
*     2.9  Responsive tabs
*     2.10 Photoswipe

*/


/*
 * Bootstrap Toolkit
 */

@import "custom/mixins-custom";

// Additional Style Reset
@import "custom/reset";

// Toolkit Core
@import "custom/flex-grid";
//@import "custom/text-responsive";
@import "custom/text-alignment";
@import "custom/pull-responsive";
@import "custom/visibility-responsive";

// Toolkit Components
@import "custom/scaffolding-custom";
@import "custom/type-custom.scss";
@import "custom/alerts-custom.scss";
@import "custom/text-styling";
@import "custom/font-awesome";
@import "custom/materialdesignicons";
@import "custom/fl-bigmug-line";
@import "custom/fl-flat-icons-set-2";
@import "custom/material-icons";
@import "custom/buttons-custom";
@import "custom/thumbnails-custom";
@import "custom/forms-custom";
@import "custom/tables-custom";
@import "custom/nav-custom";
@import "custom/panels-custom";
@import "custom/unit-responsive";
@import "custom/pagination-custom";
@import "custom/breadcrumbs-custom";
@import "custom/utilities-custom";
@import "custom/jumbotron-custom";
@import "custom/post-utilities";
@import "custom/grid-modules";
@import "custom/quotes-custom";
@import "custom/box-custom";
@import "custom/backgrounds";
@import "custom/page-title";
@import "custom/layouts";
@import "custom/layout-panel";
@import "custom/lists";
@import "custom/icons";
@import "custom/links";
@import "custom/groups";
@import "custom/offsets";
@import "custom/wrappers";
@import "custom/shop";
@import "custom/banner";
@import "custom/card-custom";
@import "custom/product-timeline";


/*
 * Custom Plugins
 */
@import "plugins/animate.scss";
@import "plugins/isotope.scss";
@import "plugins/owl-carousel.scss";
@import "plugins/rd-navbar.scss";
@import "plugins/rd-parallax.scss";
@import "plugins/rd-google-map.scss";
@import "plugins/swiper.scss";
@import "plugins/ui-to-top.scss";
@import "plugins/progress-bar";
@import "plugins/select2";
@import "plugins/timecircles";
@import "plugins/rd-search";
@import "plugins/magnific-popup";
@import "plugins/recaptcha";
@import "plugins/scrollbar";
@import "plugins/page-loaders";
@import "plugins/rd-video-player";
@import "plugins/slick";
@import "plugins/jquery.fs.stepper";
@import "plugins/rd-video";
@import "plugins/instafeed";
@import "plugins/lightgallery"; 
@import "plugins/twitter"; 
@import "plugins/facebook-feed";
@import "plugins/circle-progress";
@import "plugins/countdown";
@import "plugins/animated-text";
@import "plugins/jPlayer"; 
