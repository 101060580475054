/*
* countdown
*/
.countdown {
  font-size: 16px;
  color: $gray-darker;
  text-align: center;
}

.countdown-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;

  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -15px;
  transform: translateY(-15px);
  
  > * {
    //flex-grow: 1;
  }
}
.countdown-row .countdown-section {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}

.countdown-amount {
  display: block;
  font-size: 24px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .countdown {
    font-size: 30px;
  }
  .countdown-amount {
    font-size: 40px;
  }
}


// circle 
.contdown-circle {
  .countdown-row {
    justify-content: space-between;
  }
  
  .countdown-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 1px solid $brand-primary;
    margin-left: 5px;
    margin-right: 5px;
  }
  .countdown-period, 
  .countdown-amount {
    font-weight: 300;
    line-height: 1.2;
    font-size: 16px;
  }
  .countdown-period {
    font-size: 12px;
  }

  @media (min-width: $screen-xs-extra) {
    .countdown-section {
      width: 90px;
      height: 90px;
    }
    .countdown-period {
      font-size: 16px;
    }
    .countdown-amount {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) {
    .countdown-section {
      width: 150px;
      height: 150px;
    }
    .countdown-period {
      font-size: 20px; 
    }
    .countdown-amount {
      font-size: 36px;
    }
  }
}

// countdown boxed
.countdown-boxed {

  .countdown-row {
    justify-content: center;
  }

  .countdown-section {
    background-color: $gray-light;
    padding: 25px 0;
    box-shadow: 3px 0 8px rgba($gray-base, .15);
    min-width: 60px;
    
    .bg-gray-light & {
      background-color: $white;
    }
  }
  
  .countdown-period,
  .countdown-amount {
    font-weight: 300;
    line-height: 1.2;
    font-size: 16px;
  }
  .countdown-period {
    font-size: 12px;
  }

  @media (min-width: $screen-xs-extra) {
    .countdown-section {
      width: 90px;
    }
    .countdown-period {
      font-size: 16px;
    }
    .countdown-amount {
      font-size: 18px;
    }
  }

  @media (min-width: 768px) {
    .countdown-section {
      width: 120px;
    }
    .countdown-period {
      font-size: 26px;
    }
    .countdown-amount {
      font-size: 38px;
    }
  }
}




/**
* SVG countdown
* Countdown radial
*/

.wrap-radial-countdown { 
  flex-basis: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  > div:last-of-type{
    display: none;
  }

  @media (min-width: $screen-xs-min) {
    margin-right: -20px;
    padding: 20px;
  }

  @media (min-width: $screen-sm-min) {
    > div:last-of-type{
      display: block;
    }
  }
}

.progress-circle {
  position: relative;
  margin: 0 20px 20px 0;
  color: $gray-darker;
  flex-basis: 33%;

  @media (min-width: $screen-sm-min) {
    flex-basis: 25%;
  }
}

.progress-circle svg {
  display: block;
  width: 100%;
  height: auto;
}

.progress-circle .progress {
  fill: $brand-primary;
}

.progress-circle .circle-bg {
  fill: $gray-light;
}

.progress-circle .countdown-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  line-height: 1;
}

@media (min-width: 480px) {
  .progress-circle .countdown-counter {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .progress-circle .countdown-counter {
    font-size: 50px;
  }
}

// gradient
.stop-color-1 {
  stop-color: $brand-success;
}

.stop-color-2 {
  stop-color: $brand-primary;
}

.progress-gradient { 
  fill: url("#Gradient1");
}



.countdown-counter.mod-1 {
  color: $white;
  margin-top: 15px; 
}

//gradient radial 

.progress-circle-radial .progress {
  fill: transparent;
  stroke: $brand-primary;
  stroke-width: 6px;
}

.progress-circle-radial .circle-stroke {
  fill: transparent;
  stroke: $gray-light;
  stroke-width: 2px;
}

.progress-circle-radial {
  text-align: center;

  .countdown-counter {
    top: calc(50% - 12px);
  }
  .countdown-title {
    margin-top: 5px;
  }

  @media (min-width: $screen-xs-min) {
    .progress-counter-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0;
    }
    .countdown-counter {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
}

.progress-gradient-radial {
  fill: transparent;
  stroke: url("#Gradient2");
  stroke-width: 6px;
}

