//
// Table custom
// --------------------------------------------------
.table-custom {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  background: $table-bg;

  th,
  td {
    color: $gray-base;
    background: $table-bg;
  }

  th {
    padding: 25px 24px;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: $screen-sm-min) {
      padding: $table-head-padding;
    }
  }

  td {
    padding: $table-cell-padding;
    border-bottom: 1px solid $table-border-color;
  }

  tfoot td {
    font-weight: 700;
  }
}

// Styles
.table-custom-primary {
  th {
    color: $white;
    background: $brand-primary;
  }
}

.table-custom-light {
  thead th {
    background: $table-bg-accent;
  }

  td + td {
    border-left: 1px solid $table-border-color;
  }
}

.table-custom-bordered {
  border: 1px solid $table-border-color;
  td {
    border: 1px solid $table-border-color;
  }

  tbody > tr:first-of-type > td {
    border-top: 0;
  }
}

.table-custom-striped {
  tbody tr:nth-of-type(odd) td {
    background: transparent;
  }

  tbody tr:nth-of-type(even) td {
    background: $table-bg-accent;
  }

  tbody td {
    border: 0;
  }
}

// Modificators
.table-round {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.table-custom-responsive {
  overflow-x: auto;
  min-height: 0.01%;

  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: 10px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table-custom {
      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }
  }
}