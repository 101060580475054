/*
* @subsection   RD Google Map
* @description  Describes style declarations for RD Google Map extension
* @version      1.0.0
*/
$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 400px;
$map-lg-height: 450px;
$map-xl-height: 520px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;

  @media (min-width: $screen-sm-min) {
    height: $map-sm-height;
  }

  @media (min-width: $screen-lg-min) {
    height: $map-xl-height;
  }
}

