//
// ProgressBars
// --------------------------------------------------


// progress bootstrap 
.progress-bar-bs {
  position: relative;
  padding-right: 60px;
  height: auto;
  margin: 0;

  .progress {
    height: 10px;
    margin-bottom: 0;
    @include box-shadow(none);
    border-radius: 20px;
  }

  .progress-bar {
    @include box-shadow(none);
    border: 0;
    border-radius: 20px;
    height: auto;
  }

  .caption {
    position: absolute;
    bottom: -6px;
    right: 5px;
    width: 60px;
    padding-left: 20px;
    text-transform: uppercase;
    color: $gray;
  }
}

// progress square
.progress-bar-square {
  .progress {
    height: 15px;
  }
  .caption {
    bottom: -4px;
  }
  .progress, 
  .progress-bar {
    border-radius: 0;
  }
}

//progress vertical
.progress-bar-vertical {
  display: flex;
  padding-top: 50px;
  padding-right: 0;
  
  .progress {
    width: 10px;
    min-height: 230px;
    display: flex;
    align-items: flex-end; 
  }
  .progress-bar {
    width: 100%;
    height: 0; 
  }
  .list-bars-item-header {
    font-size: 14px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
  .caption {
    width: auto;
    bottom: auto;
    top: 0;
    left: 4px;
    padding-left: 0;
  }
}

.progress-bar {
  width: 0;
  .active & {
    animation: progress 1.5s ease-in-out forwards;
  }
}
.progress-bar-vertical {
  &.active {
    .progress-bar {
      animation: progressVertical 1.5s ease-in-out forwards;
    }
  }
}
@keyframes progress {
  from {
    width: 0; 
  }
  to {
    width: 100%;
  }
}
@keyframes progressVertical {
  from {
    height: 0; 
  }
  to {
    height: 100%;
  }
}


.progress-variant-primary {
  .progress {
    background: $gray-light;
  }

  .progressbar {
    background: $brand-primary;
  }
}

.progress-variant-secondary-1 {
  .progress {
    background: $gray-lighter;
  }

  .progress-bar {
    background: #f8333c;
  }
}
.progress-variant-secondary-2 {
  .progress {
    background: $gray-lighter;
  }

  .progress-bar {
    background: #45a4ff;
  }
}
.progress-variant-secondary-3 {
  .progress {
    background: $gray-lighter;
  }

  .progress-bar {
    background: #fde74c;
  }
}
.progress-variant-secondary-4 {
  .progress {
    background: $gray-lighter;
  }

  .progress-bar {
    background: #58c4a7;
  }
}
.progress-gradient {
  .progress {
    background: $gray-lighter;
  }

  .progress-bar {
    background-image: linear-gradient(to right, $brand-primary, $brand-success);
  }
  
  &.progress-bar-vertical {
    .progress-bar {
      background-image: linear-gradient(to top, $brand-primary, $brand-success);
    }
  }
}


.list-bars-vertical {
  > li {
    display: inline-block;
    vertical-align: top;
    
    + li {
      margin-left: 40px;
      margin-top: 0;
    }
    @media (min-width: $screen-sm-min) {
      + li {
        margin-left: 60px;
      }
    }
  }

  * + .progress {
    margin-top: 0;
    margin-left: 10px;
  }
}


.list-bars-variant-1 {
  max-width: 375px;
  
  .progress-bar-bs .progress {
    height: 4px;  
  }

  .progress-bar-bs {
    padding-right: 0;

    .caption {
      width: auto;
      bottom: 8px;
      right: 0;
    }
  }

  .list-bars-item-header,
  .caption {
    color: $gray-darker;
  }

  .list-bars-item-header {
    padding-right: 60px;
  }

   * + .progress {
     margin-top: 5px;
   }
}