//
// Custom typography
// --------------------------------------------------

// Page
// -------------------------

body {
  color: $text-color; 
}

.page {
  overflow: hidden;
}

// Page header
// -------------------------

.page-header {
  padding: 0;
  margin: 0;
  border-bottom: none;
}

// Fonts
// -------------------------

.font-default {
  font-family: $font-family-base;
}

// Basic Typography
// -------------------------
@mixin header-base-styles {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  letter-spacing: -.025em; 
  color: $headings-color;
}

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  @include header-base-styles;

  a {
    display: inline;
    font: inherit;
    letter-spacing: inherit;
    @include link(inherit, $link-hover-color);
  }

  &.mark {
    display: inline-block;
  }

  .icon {
    font-size: .95em;
  }

  > * {
    display: inline-block;
  }
}

// {0 - 768px} <= 40px
// {768px - 992px} <= 62px
@mixin header-h1 {
  font-size: 36px;
  line-height: 1.2;

  @media (min-width: $screen-sm-min) {
    font-size: 56px;
    line-height: $font-lh-h1;
  }

  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h1;
  }
}


// {0 - 768px} <= 36px
// {768px - 992px} <= 52px
@mixin header-h2 {
  font-size: 28px;
  line-height: 1.3;

  @media (min-width: $screen-sm-min) {
    font-size: 40px;
    line-height: $font-lh-h2;
  }

  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h2;
  }
}

// {0 - 768px} <= 32px
// {768px - 992px} <= 44px
@mixin header-h3 {
  font-size: 26px;
  line-height: 1.45;

  @media (min-width: $screen-sm-min) {
    font-size: 34px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h3;
    line-height: $font-lh-h3;
  }
}

// {0 - 768px} <= 26px
// {768px - 992px} <= 36px
@mixin header-h4 {
  font-size: 22px;
  line-height: 1.35;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h4;
    line-height: $font-lh-h4;
  }
}

// {0 - 768px} <= 22px
// {768px - 992px} <= 28px
@mixin header-h5 {
  font-size: $font-size-h5;
  line-height: $font-lh-h5;
  letter-spacing: .075em;
}

// {0 - 768px} <= 18px
// {768px - 992px} <= 22px
@mixin header-h6 {
  font-size: $font-size-h6;
  line-height: $font-lh-h6;
}

h1, .h1 {
  @include header-h1;

  &.xl { 
    font-size: 112px;
  }
}

h2, .h2 {
  @include header-h2;
}

h3, .h3 {
  @include header-h3;
}

.heading-3 {
  @include header-h3;
  font-weight: 400;
  letter-spacing: -.025em; 
}

h4, .h4 {
  @include header-h4;
}

h5, .h5 {
  @include header-h5;
}

h6, .h6 {
  @include header-h6;
}

.h7 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 13px;
  font-weight: 900;
  color: $gray-darker;  
}

.h3-alternate {
  @include header-base-styles;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.45;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    font-size: 22px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: $font-size-h3;
    line-height: $font-lh-h3;
  }
}

.h4-alternate {
  color: $headings-color;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;

  @media (min-width: $screen-sm-min) {
    font-size: 22px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 24px;
  }
}

.header-lighter {
  font-family: $font-family-sec;
  font-weight: 400;
  text-transform: none;
}

code {
  padding: 5px 7px;
  font-size: 75%;
  color: $brand-primary;
  background-color: $white;
}

mark,
.mark {
  line-height: 1.04;
  padding: .15em .4em .1em;
  color: $white;
  background: $brand-primary;
}

.mark-spacing-1 {
  padding: .4em .5em .4em;
}

.mark-spacing-2 {
  padding: .2em .6em .14em;
}

.mark-alternate {
  text-transform: uppercase;
  background: $tan-hide;
}

@media (min-width: $screen-xs) {
  .big {
    font-size: $font-size-large;
    line-height: floor(($font-size-large * $line-height-large));
  }
}

small,
.small {
  font-size: $font-size-small;
  line-height: floor(($font-size-small * $line-height-small));
}

.large {
  font-family: $font-family-sec;
  font-size: 16px;

  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

pre{
  padding: 25px 20px;
  background-color: $gray-light;
  border: 1px solid $porcelain; 
  overflow-y: auto;
  margin-bottom: 0;
  text-align: left;
}
p {

}
code {
  padding: 5px 7px;
  font-size: 75%;
  color: $brand-danger;
  background-color: $gray-lighter;
  border-radius: 2px;
}

[data-toggle='tooltip'] {
  display: inline-block;
  color: $nobel;
}

.tooltip {
  font-size: $font-size-base;
  z-index: 1;

  &.top .tooltip-arrow {
    bottom: 1px;
  }
}

// Colors
// -------------------------

.page {
  .text-primary {
    color: $brand-primary;
  }

  .text-dark {
    color: $gray-dark;
  }

  .text-gray {
    color: $gray;
  }

  .text-gray-darker {
    color: $gray-darker;
  }

  .text-white {
    color: $white;
  }

  .text-porcelain {
    color: $porcelain;
  }

  .text-blue-marguerite {
    color: $blue-marguerite;
  }

  .text-success {
    color: $brand-success;
  }

  .text-jaffa {
    color: $jaffa;
  }
}


//team member 

.team-member-wrap {
  @media (min-width: $screen-md-min) {
    padding-left: 70px;
  }
}

.team-member-position {
  position: relative;

  span {
    display: inline-block;
    padding: 3px 12px;
    background-color: $brand-primary;
    color: $white;
  }

  @media (min-width: $screen-xs-min) {
    &:before{
      position: absolute;
      top: 0;
      left: -15px;
      content: "";
      display: inline-block;
      @include triangle(left,15px,28.5px,$brand-primary);
      vertical-align: middle;
    }
  }
}

// img wrap
//

.img-responsive {
  display: inline-block;
  width: 100%;
  height: auto;
}

.img-wrap-mod-2 {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
    max-width: auto;
  }

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: inline-block;
    background-color: rgba($black, .5);
    opacity: 0;
    transition: 250ms;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    @include transform(translate(-50%, -50%) scale(0));
    transition: 250ms;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
    .icon {
      @include transform(translate(-50%, -50%) scale(1));
      opacity: 1;
    }
  }
}

.subtitle-variant-1 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.subtitle-variant-2 {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  
  * + & {
    margin-top: 8px;
  }
}

.subtitle-variant-3 {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: .075em;
  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

.list-collumn-2 {
  @media (min-width: $screen-sm-min) {
    columns: 2;
    column-gap: 30px;
  }
}

// section full viewport
.section-intro {
  display: flex;
  align-items: center;
  min-height: 100vh;
  .ie-10 &,
  .ie-11 &,
  .ie-edge & {
    height: 100vh;
  }
}

.sale-banner-wrap {
  position: relative;
  box-shadow: 28px 23px 62px rgba($gray-base, .25);
  @media (min-width: $screen-md-min) {
    margin-right: 40px; 
  }
  @media (min-width: 1600px) {
    margin-right: 0;
  }
}

.sale-banner {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 29px 29px 40px rgba($gray-darker, .25);

  @media (min-width: $screen-md-min) {
    transform: translate(50%, -50%);
  }
}

.sale-banner-inner {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: $gray-darker;
  
  .price {
    display: block;
    margin-top: 5px;
    color: $brand-danger;
    font-weight: 700;
    font-size: 42px;
    line-height: .7;
    &:before{
      content: "$";
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      vertical-align: top;
    }
  }
}


// slide down wrapper
//-----------------------------

.value1 {
  &:before{
    content: "\f055";
    font: 400 18px/1.3 "FontAwesome";
    display: inline-block;
    vertical-align: middle;
    padding-right: 7px;
  }

  .slide-down-toggle.active & {
    &:before {
      content: "\f056";
    }
  }
}


// img hover

.img-layout:hover .img-layout-inner {
  transform: translateY(-5px);
}

.img-layout .img-layout-inner {
  transition: 450ms ease-in-out;
  will-change: transform;
}
.img-layout:hover img {

  box-shadow: 0 9px 27px rgba(0, 0, 0, 0.58);
}

.img-layout img {
  width: 100%;
  height: auto;
  transition: 450ms ease-in-out;
}


.iframe-mod {
  width: 100%;
  border: 0;
}
// iframe header

.frame-header {
  width: 100%;
  height: 500px;
  outline: none;
  border: 0;  
}

* + .frame-header {
  margin-top: 40px;
}

@media (min-width: $screen-sm-min) {
  * + .frame-header {
    margin-top: 60px;
  }
}

@media (min-width: $screen-md-min) {
  .frame-header {
    &_mod1 {
      height: 750px;
    }
  }
}