/*
* @subsection   ToTop
* @license      JeremyLuis and Atom
* @see          https://codepen.io/jeremyLuis/pen/YxEPGL
* @version      1.0.0
*/
.ui-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 36px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  transition: .3s all ease;
  transform: translateY(100px);

  &:before{
    content: "\f106";
    display: inline-block;
    font: 400 24px/1 'FontAwesome';
  }

  &,
  &:active,
  &:focus {
    color: $white;
    background: $brand-primary;
  }

  &:hover {
    color: $white;
    background: $gray-3;
  }

  &.active {
    transform: translateY(0);
  }
}

@media (min-width: $screen-xs-min) {
  .ui-to-top {
    right: 40px;
    bottom: 40px;
  }
}