/**
* Instagram Plugin Style
*/

/**
*  Thumbnail InstaFeedl
*/

.thumbnail-instafeed {
  position: relative;
  padding-bottom: 100%;

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba($brand-primary, .35);
    transition: .3s ease-out all;
    pointer-events: none;
    overflow: hidden;
    will-change: transfrom;

    .list-inline {
      margin-left: -10px;
      margin-right: -10px;
      > li {
        padding-left: 10px;
        padding-right: 10px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .icon {
      margin-right: 5px;
      font-size: 28px;
    }
  }
}

.ie-10,
.ie-11 {
  .thumbnail-instafeed {
    overflow: hidden;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      height: auto;
      max-width: 135%;
      max-height: 135%;
      min-height: 101%;
      min-width: 101%;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .thumbnail-instafeed {
    .caption {
      .list-inline {
        margin-left: -25px;
        margin-right: -25px;
        > li {
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }
}

/**
*  Thumbnail InstaFeedl Minimal
*/
.thumbnail-instafeed-minimal {
  .caption {
    .list-inline {
      > li {
        display: block;
      }
      > li + li { margin-top: 10px; }
    }
  }
}

.desktop {
  @media (min-width: $screen-md-min) {
    .thumbnail-instafeed {
      .caption {
        background-color: rgba($brand-primary, .8);
      }
      .caption,
      .list-inline > li {
        visibility: hidden;
        opacity: 0;
      }
      .list-inline > li {
        transform: scale3d(0, 0, 0);
        will-change: transfrom;
      }
      &:hover {
        .caption,
        .list-inline > li {
          visibility: visible;
          opacity: 1;
        }
        .list-inline > li {
          transform: scale3d(1, 1, 1);
          transition: 480ms cubic-bezier(0.18, 0.89, 0.32, 1.28) transform, 150ms cubic-bezier(0.18, 0.89, 0.32, 1.28) opacity;
          transition-delay: .15s;
        }
      }
    }
  }
}

* + .instafeed { margin-top: 30px; }

.instafeed {
  .owl-carousel {
    .owl-next,
    .owl-prev {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .owl-stage-outer {
      overflow: visible;
    }
    .owl-item{
      transition: 300ms ease-in-out;
      transform-origin: bottom;
    }
    .owl-item:hover {
      transform: scale(1.2);
      z-index: 1;
    }
  }
}