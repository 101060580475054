/** RD Facebook Feed */
.post-facebook {
  position: relative;
  padding: 20px;
  background: $white;
  border: 1px solid $gray-light;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  
  > * + * {
    margin-top: 15px;
  }
}

.facebook-meta {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-left: -15px; 
  
  @media (min-width: $screen-xs-min) {
    justify-content: space-between;
  }
  
  > * {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-top: 5px;
  }
}

.facebook-meta .time {
  font-size: 15px;
  color: $text-color;
}

.post-facebook .post-meta_profile-img {
  display: inline-block;
  border-radius: 50%;
}

.post-facebook .post-meta_profile-name {
  display: inline-block;
  margin: 0;
}

.facebook-message {
  max-height: 120px;
  overflow: hidden;
}

.post-facebook .post-attachment {
  margin: 25px 0;
  position: relative;
}

.post-facebook .post-attachment-wrap {
  margin-top: 15px;
}

.post-facebook .post-attachment-wrap > p {
  margin-top: 5px;
  color: $text-color;
}

.post-facebook .icon-xxs {
  font-size: 20px;
}

.post-facebook .post-comments h6 {
  margin-bottom: 0;
}

.post-facebook .post-comments .post-comment {
  padding: 15px 0;
}

.post-facebook .post-comments .post-comment .comment-message {
  margin-top: 10px;
}

@media (min-width: 1200px) {
  .post-facebook {
    padding: 24px 30px 30px 30px;
  }
  .post-facebook .post-attachment {
    padding-left: 20px;
    border-left: 1px solid $gray-light;
  }
}

.footer-brand img {
  max-height: 52px;
  width: auto;
}

.facebook-attachment img {
  max-width: 100%;
}
