//
// Responsive Offsets
// --------------------------------------------------
@mixin responsive-offset($offset) {
  transform: translate3d(0,-$offset,0);
  margin-bottom: -$offset;
  pointer-events: none;

  > * {
    margin-top: $offset;
    pointer-events: auto;

    &::before {
      content: '';
      display: table;
      width: 0;
    }
  }
}

@mixin responsive-offset-media($offset) {
  transform: translate3d(0, -$offset, 0);
  margin-bottom: -$offset;
  > * { margin-top: $offset; }
}

.spacing-negative-1 {
  @include responsive-offset(-1px);
}

.spacing-0 {
  @include responsive-offset(0);
}
.spacing-10 {
  @include responsive-offset(10px);
}

.spacing-20 {
  @include responsive-offset(20px);
}

.spacing-30 {
  @include responsive-offset(30px);
}

.spacing-40 {
  @include responsive-offset(40px);
}

.spacing-50 {
  @include responsive-offset(50px);
}

.spacing-55 {
  @include responsive-offset(55px);
}

.spacing-70 {
  @include responsive-offset(50px);
}

.spacing-90 {
  @include responsive-offset(50px); 
}

@media (min-width: $screen-sm-min) {
  .spacing-70 {
    @include responsive-offset-media(70px);
  }

  .spacing-90 {
    @include responsive-offset-media(90px);
  }
}

// Insets
// -------------------------
.inset-left-25 {
  padding-left: 25px;
}

@media (min-width: $screen-md-min) {

  .inset-sm-25 {
    padding: 0 25px;
  }
}

// Sections
// -------------------------

.section-xs {
  padding: 40px 0;
}

.section-md-top {
  padding-top: 50px;
}

.section-md-bottom {
  padding-bottom: 50px;
}

.section-sm,
.section-md,
.section-lg,
.section-xl {
  padding: 60px 0;
}

@media (max-width: $screen-xs-max) {
  section {
    &:first-child {
      &.section-sm,
      &.section-md,
      &.section-lg,
      &.section-xl {
        padding-top: 40px;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  section {
    &:first-child {
      &.section-sm {
        padding-top: 100px;
      }
    }
  }

  .section-md {
    padding: 70px 0;
  }

  .section-lg {
    padding: 80px 0;
  }

  .section-xl {
    padding: 95px 0;
  }
}

@media (min-width: $screen-md-min) {
  //section {
  //  &:first-of-type {
  //    &.section-sm,
  //    &.section-md,
  //    &.section-lg,
  //    &.section-xl {
  //      padding-top: 280px;
  //    }
  //  }
  //}
  
  .section-sm {
    padding: 60px 0;
  }

  .section-md {
    padding: 95px 0;
  }

  .section-md-alternate {
    padding-bottom: 60px;
  }

  .section-md-top {
    padding-top: 95px;
  }

  .section-md-bottom {
    padding-bottom: 95px;
  }

  .section-lg {
    padding: 100px 0;
  }

  .section-xl {
    padding: 130px 0;
  }
}

@media (min-width: $screen-lg-min) and (min-height: $screen-height-desktop) {

  .section-xl {
    padding: 150px 0;
  }
}

// Elements
// -------------------------

* + h1,
* + .h1 {
  margin-top: 15px;
}

* + h2,
* + .h2 {
}

* + h3,
* + .h3 {
  margin-top: 20px;
}

* + h4,
* + .h4 {
  margin-top: 20px;

  &.header-lighter {
    margin-top: 22px;
  }
}

* + h5,
* + .h5 {
  margin-top: 15px;
}

* + h6,
* + .h6 {
}

* + pre {
  margin-top: 40px;
}

* + hr {
  margin-top: 10px;
}

h1 + p,
.h1 + p {
  //margin-top: 15px;
}

h2 + p,
.h2 + p {
  //margin-top: 10px;
}

h3 + p,
.h3 + p {
}

h4 + p,
.h4 + p {
  //margin-top: 6px;
}

h5 + p,
.h5 + p {
  //margin-top: 14px;
}

h6 + p,
.h6 + p {
}

* + p,
* + .p {
  margin-top: 20px;
}

* + .h7 {
  margin-top: 20px;
}

* + .big {
  margin-top: 8px;
}

* + .large {
  margin-top: 10px;
}

* + .btn {
  margin-top: 22px;
}

* + .btn.btn-offset-medium {
  margin-top: 20px;
}

* + .btn.btn-offset-small {
  margin-top: 14px;
}

* + .mark-bigger {
  margin-top: 12px;
}

* + .marked-list,
* + .ordered-list {
  margin-top: 12px;
}

* + .quote-primary {
  margin-top: 40px;
}

* + .row {
  margin-top: 30px;
}

* + .rd-mailform {
  margin-top: 40px;
}

* + .form-inline {
  margin-top: 10px;
}

* + .figure-lighter {
  margin-top: 30px;
}

.text-decoration-lines + * {
  margin-top: 25px;
}

* + .group-xs {
  margin-top: 15px;
}

* + .group-sm {
  margin-top: 35px;
}

* + .group-xl {
  margin-top: 30px;
}

* + .privacy-link {
  margin-top: 30px;
}

* + .list-bars {
  margin-top: 25px;
}

* + .inline-list-sm {
  margin-top: 22px;
}

* + .inline-list-xs {
  margin-top: 20px;
}

* + .inline-list-lg {
  margin-top: 20px;
}

* + .box-cta {
  margin-top: 30px;
}

* + .cta-wrap,
.cta-wrap + * {
  margin-top: 30px;
}

* + .tabs-line {
  margin-top: 35px;
}

* + .list-md {
  margin-top: 35px;
}

* + .box-contact-info {
  margin-top: 40px;
}

* + .rd-video-player {
  margin-top: 24px;
}

* + .flickr {
  margin-top: 30px;
}

p + p,
p + img,
p + .marked-list{
  margin-top: 24px;
}

p + h2 {
  margin-top: 10px;
}

p + .rd-mailform {
  margin-top: 18px;
}

p + .quote-primary,
.quote-primary + p {
  margin-top: 24px;
}

p + .row {
  margin-top: 40px;
}

p + .group-sm {
  margin-top: 15px;
}

p + .group-md {
  margin-top: 30px;
}

img + h4,
img + .h4 {
  margin-top: 30px;
}

h4 + .checkbox-inline,
.h4 + .checkbox-inline {
  margin-top: 15px;
}

h4 + .marked-list {
  margin-top: 25px;
}

.checkbox + h4,
.checkbox + .h4 {
  margin-top: 30px;
}

.h7 + .rd-mailform {
  margin-top: 25px;
}

.marked-list + .btn {
  margin-top: 30px;
}

.container + .container {
  margin-top: 45px;
}

.row + h2 {
  margin-top: 70px;
}

.row + .group-md {
  margin-top: 50px;
}

.big + .group-sm{
  margin-top: 40px;
}

* + .row.flickr {
  margin-top: 14px;
}

section {
  * + .section,
  .section + * {
    margin-top: 40px;
  }
  
  * + .page-footer { 
    margin-top: 40px;
  }
  
  .section + .slide-down-wrapper {
    margin-top: 0;
  }
  
  section.section-lg {
    padding-top: 0;
    padding-bottom: 0;
  }
}

html {
  * + .offset-custom-1 {
    margin-top: 40px;
  }
  * + .offset-custom-2 {
    margin-top: 10px;
  }
  .row-30 {
    @include responsive-offset(30px);
  }
}

@media (min-width: $screen-xs-min) {
  * + .btn {
    margin-top: 30px;
  }
}

@media (min-width: $screen-sm-min) {
  * + h4,
  * + .h4 {
    margin-top: 30px;
  }
  * + h5,
  * + .h5 {
    margin-top: 30px;
  }

  * + hr {
    margin-top: 25px;
  }

  * + .big {
    margin-top: 12px;
  }

  * + .btn {
    margin-top: 50px;
  }

  * + .row {
    margin-top: 50px;
  }

  * + .figure-lighter {
    margin-top: 40px;
  }

  * + .group-xl {
    margin-top: 50px;
  }

  * + .list-bars {
    margin-top: 40px;
  }

  * + .inline-list-lg {
    margin-top: 10px;
  }

  * + .h7 {
    margin-top: 10px;
  }

  * + .tabs-line {
    margin-top: 20px;
  }

  .container + .container {
    margin-top: 70px;
  }
  .row + h2 {
    margin-top: 90px;
  }
  .row + .group-md {
    margin-top: 75px;
  }
  
  p + .group-md {
    margin-top: 40px;
  }

  p + .owl-carousel {
    margin-top: 45px;
  }
}

@media (min-width: $screen-lg-min) {
  * + .big {
    margin-top: 24px;
  }

  .container + .container {
    margin-top: 100px;
  }
  p + .row {
    margin-top: 75px;
  }
}