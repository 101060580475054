/*
* Sidebar Layout
*/

.rd-navbar-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: rgba($gray-base, .43);
  
  * + .group-sm {
    margin-top: 0;
  }

  // RD Navbar inner
  .rd-navbar-inner {
    position: relative;
    @include display-flex;
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
    max-width: $rd-navbar-width;
    padding: 24px 15px;
    height: 85px;
    margin-left: auto;
    margin-right: auto;
    transition: height .3s;
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    @include make-toggle(
                    'button-lines-cross-3',
                    $rd-navbar-min-line-height,
                    28px,
                    $white
    );

    span {
      &:after,
      &:before,
      & {
        height: 3px;
      }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .rd-navbar-nav-wrap {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 90px;
    opacity: 0;
    @extend %rd-navbar-transition;
    overflow: visible;

    background: $white;
    text-align: center;
    border-bottom: 3px solid $brand-success;
    pointer-events: none;

    &.active {
      opacity: 1;
      top: 85px;
      pointer-events: auto;
    }
  }

  // RD Navbar megamenu
  .rd-navbar-megamenu {
    left: 50%;
    right: 0;
    width: 100%;
  }

  .rd-navbar-nav {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(center);
    padding: 50px 40px;
    border-style: solid;
    border-color: darken($white, 5%);
    border-width: 0 1px 0 1px;
    overflow: visible;

    > li > .rd-navbar-dropdown {
      margin-top: 49px;
      border-style: solid;
      border-color: darken($white, 5%);
      border-width: 0 1px 1px 1px;
    }
    
    > li {
      padding-left: 32px;
      padding-right: 32px;
    }
    
  }

  .rd-navbar-megamenu {
    right: 0;
    margin-top: 12px;
    border: 1px solid darken($white, 5%);
    border-top: 0;
    border-bottom: 3px solid $brand-success;

    > li {
      padding-bottom: 35px;
    }
  }

  .btn-sm {
    font-size: 13px;
  }

  &.rd-navbar--is-stuck {
    background: $gray-dark;

    .rd-navbar-inner {
      height: 74px;
    }

    .rd-navbar-nav-wrap {
      top: 84px;
      &.active {
        top: 74px;
      }
    }
  }
}