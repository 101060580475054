// product timeline

.product-timeline-divider{
  display: none;
  border-top: 1px solid rgba(#cbd4ff, .3);
  margin-top: 19px;
  margin-bottom: 15px;
  position: relative;

  @media (min-width: $screen-md-min) {
    margin-top: 40px;
  }
}
.product-timeline-divider:after{
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 0 6px rgba($white, .17); 
  background: $white;
  display: none;
}
.product-timeline-divider:before{
  width: 185px;
  height: 185px;

  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba(#4b7fca, .10);
  border: 1px solid rgba(#4b7fca, .36);
  z-index: 0;

  display: none;
}

.product-timeline{
  position: relative;
  
  > .product-timeline-item {
    &:nth-child(1) {
      .product-timeline-divider:before{
        width: 109px;
        height: 109px;
      }
    }
    &:nth-child(2) {
      .product-timeline-divider:before{
        width: 143px;
        height: 143px;
      }
    }
    &:nth-child(3) {
      .product-timeline-divider:before{
        width: 183px;
        height: 183px;
      }
    }
    &:nth-child(4) {
      .product-timeline-divider:before{
        width: 236px;
        height: 236px;
      }
    }
    
  }
}

.product-timeline:before{
  content: "";
  position: absolute;
  width: 2px;
  bottom: 0;
  background: #1A1B1F;
  left: 28px;
  top: 0;
  display: none;
}
.product-timeline.spacing-60:before{
  top: 60px;
  bottom: 0;
}

.product-timeline-item-icon {
  z-index: 1;
}
.product-timeline-item{
  position: relative;
  
  p {
    display: inline-block;
  }
  
  > * {
    position: relative;
  }
}
.product-timeline-item:after{
  content: "";
  position: absolute;
  left: 24px;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #457AF1;
  display: none;
}

@media (min-width: 768px){
  .product-timeline:before {
    display: none;
  }
  .product-timeline-item:after {
    display: none;
  }
  .product-timeline-divider:after {
    display: block;
  }
  .product-timeline-divider {
    display: block;
  }
}

@media (min-width: $screen-md-min) {
  .product-timeline-divider:before {
    display: block;
  }
}