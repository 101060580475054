// Banner 
.banner-box {
  position: relative;
  overflow: hidden;
  text-align: left;
  
  figure {
    margin: 0;
  } 
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-weight: 900;
  }
}

//variant1
.banner-box-variant-1 {
  padding: 10px;
  background-color: $gray-light;
  
  .caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 15px;
    background-color: rgba($white, .7);
  }
  
  .bg-gray-light & {
    background-color: $white;
  }

  @media (min-width: $screen-sm-min) {
    padding: 20px;
    .caption {
      bottom: 50px;
      left: 50px;
    }
  }
}

// variant2
.banner-box-variant-2 {
  border-left: 1px solid;
  color: $gray-darker;  
  
  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 30px;
    padding-left: 15px;

    &:before{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      display: inline-block;
      background-color: $white;
      max-width: 150px;
    }
    
    > * {
      position: relative;
      max-width: 150px;
    }
  }

  .banner-title {
    max-width: 100%;
    margin-top: 5px;
    padding: 15px 15px 20px;
    white-space: nowrap;
    background-color: $brand-primary;
    color: $white;
  }

  .bg-gray-light & {
    .caption:before {
      background-color: $gray-light;
    }
  }
}

// variant3
.banner-box-variant-3 {
  padding: 10px;
  background-color: $gray-darker;
  
  figure {
    background-color: $white;
    padding: 25px;
    img  {
      opacity: .7;
    }
  }
  
  .caption {
    position: absolute;
    left: 45px;
    bottom: 45px;
  }
  
  .banner-sale-block {
    position: absolute;
    top: 0;
    right: 20px;
    text-align: right;
    background-color: #78abf1;
    line-height: 1;
    margin-top: 0;
    padding: 10px;
    color: $white;
    
    > span {
      display: block;
      font-weight: 700;
      
      &:nth-child(1) {
        font-size: 16px
      }
      &:nth-child(2) {
        font-size: 26px;
      }
      &:nth-child(3) {
        font-size: 36px;
      }
      &:nth-child(4) {
        font-size: 26px;
        letter-spacing: .26em;
        font-weight: 900;
        text-transform: uppercase;
      }
    }
  }

  @media (min-width: $screen-xs-min) {
    .banner-sale-block {
      > span {
        display: block;
        &:nth-child(1) {
          font-size: 22px
        }
        &:nth-child(2) {
          font-size: 36px;
        }
        &:nth-child(3) {
          font-size: 50px;
        }
        &:nth-child(4) {
          font-size: 36px;
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .banner-sale-block {
      > span {
        display: block;
        &:nth-child(1) {
          font-size: 27px
        }
        &:nth-child(2) {
          font-size: 45px;
        }
        &:nth-child(3) {
          font-size: 64px;
        }
        &:nth-child(4) {
          font-size: 50px;
          position: relative;
          right: -6px;
        }
      }
    }
  }
}

// variant 4

.banner-box-variant-4 {
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    
    &:before{
      position: absolute;
      top: -30px;
      bottom: -30px;
      left: 40px;
      right: 40px;
      content: "";
      display: inline-block;
      background-color: rgba($brand-primary,.5);
      border: 8px solid $brand-primary;
    }
    
    > * {
      position: relative;
    }
    
    > * + * {
      margin-top: 35px;
    }
    * + .banner-title {
      margin-top: 25px;
    }
  }
  .banner-title {
    white-space: nowrap; 
  }
}


// variant5
.banner-box-variant-5 {
  padding: 25px 35px;
  background-image: url(../images/bg-image-6.jpg);
  background-size: cover;
  
  .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: -25px;
    transform: translateY(-25px); 
    
    > * {
      margin-top: 25px;
    }
  }
  
  .title-group {
    > * {
      color: $white;
    }
  }
}

// variant6
.banner-box-variant-6 {
  max-width: 250px;
  padding: 25px 15px;
  background-color: $gray-light;

  > * {
    position: relative;
    z-index: 2;
  }
  
  figure {
    margin-top: 15px;
    z-index: 0;
  }
  
  &:before{
    position: absolute;
    top: 0;
    content: "";
    width: 80%;
    height: 50%;
    max-width: 128px;
    background-color: rgba($brand-primary, .5);
    z-index: 1;
  }

  @media (min-width: $screen-sm-min) {
    padding: 45px 20px;
  }
}